import React, { useState } from "react";
import { toast } from "react-toastify";
import config from "../config/config";
import saveToken from "./localStorage/saveToken";

const useFetchLogin = () => {
  const apiBaseAuthentificationUrl = config.authentificationUrl;
  const [loading, setLoading] = useState(true);

  const fetchLogin = async (login, password, navigate) => {
    setLoading(true);

    try {
      const response = await fetch(apiBaseAuthentificationUrl + "/login", {
        method: "POST",
        body: JSON.stringify({
          login: login,
          password: password,
        }),
      });

      if (!response.ok) {
        throw new Error(await response.text());
      }

      const data = await response.json();
      const token = data.data;
      saveToken("token", token);
      toast.success(<>{data.message}</>);
      navigate("/");
    } catch (error) {
      const errorMessage = JSON.parse(error.message).message;
      toast.error(<>{errorMessage}</>);
    } finally {
      setLoading(false);
    }
  };

  return { fetchLogin, loading };
};

export default useFetchLogin;
