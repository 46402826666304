import './CardView.css';
import React, { useState, useRef, useEffect, createRef } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const CardView = ({rows, config, rowClick}) => {

    const [blocSize, setBlocSize] = useState(2);

    const iconRefs = useRef(rows.map(() => createRef()));

    useEffect(() => {
        iconRefs.current.forEach((iconRef, index) => {
            const headerHeight = document.querySelector('.resizableTable-card .card-header')?.clientHeight || 0;
            const iconHeight = iconRef.current?.clientHeight || 0;

            if (headerHeight && iconHeight) {
                const topPosition = headerHeight - iconHeight / 1.65;
                iconRef.current.style.top = `${topPosition}px`;
            }
        });
    }, [rows]);
    
    let i = 0;
    
    return (

        <Row className='w-100 mb-2 ps-2'>
            {
                rows &&
                Object.keys(rows).map((rowKey, rowIndex) => {
                    const row = rows[rowKey];
                    return (
                        <Col key={rowIndex} xl={blocSize} lg={blocSize+1} md={blocSize+2} sm={blocSize+4} className='p-0 ps-2'>
                            <Card className='resizableTable-card mt-2 position-relative ' onClick={ rowClick && (() => rowClick(row.id))}>
                                <Card.Header className={'h6 text-end border-bottom-0 pe-2 '+config.headerClass(row)}>{config.header ? config.header(row) : null}</Card.Header>
                                <Card.Body className='pt-1 pe-2'>
                                    <Card.Title className='h6 p-0 text-end'>{config.title ? config.title(row) : null}</Card.Title>
                                    <Card.Text className='pt-2'>
                                        {config.text ? config.text(row) : null}
                                    </Card.Text>
                                </Card.Body>
                                {config.footer &&
                                    <Card.Footer>
                                        {config.footer(row)}
                                    </Card.Footer>
                                }
                                {config.icon && 
                                    <div ref={iconRefs.current[rowIndex]} className={'icon-card position-absolute '+config.headerClass(row)} >
                                        {config.icon ? config.icon(row) : null}
                                    </div>
                                }
                            </Card>
                        </Col>
                    )
                })
            }
        </Row>

    );
};

export default CardView;