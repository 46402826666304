import React from "react";
import Moment from "react-moment";

function FormatDateDDMMYYYY (props) {

    return (
        <Moment format="DD/MM/YYYY">
                {props.children}
        </Moment>
    );
}

export default FormatDateDDMMYYYY;