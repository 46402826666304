import React, { useEffect, useState } from "react";
import { VscError, VscPass } from "react-icons/vsc";
import { toast } from "react-toastify";

const useFetch = (url, method, isComponentMounted, initialValue) => {
  const [data, setData] = useState(initialValue);
  const [loading, setLoading] = useState(true);

  const fetchData = async (newUrl, options = {}, onSuccess, onError) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json, text/html");
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      const defaultOptions = {
        method: method,
        credentials: "include",
        headers: myHeaders,
      };

      const mergedOptions = { ...defaultOptions, ...options };

      const response = await fetch(newUrl, mergedOptions);

      if (!response.ok) {
        if (
          response.headers.get("content-type")?.includes("application/json")
        ) {
          const errorJson = await response.json();
          throw new Error(errorJson.message);
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }

      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes("application/json")) {
        const resJson = await response.json();
        if (resJson.toast) {
          if (onSuccess) {
            onSuccess({
              icon: <VscPass size={20 + "%"} />,
              message: resJson.message,
            });
          } else {
            toast.success(<>{resJson.message}</>);
          }
        }
        setData(resJson.data);
      } else {
        const resHtml = await response.text();
        console.log("Response is HTML: ", resHtml);
      }
    } catch (error) {
      if (onError) {
        onError({ icon: <VscError size={20 + "%"} />, message: error.message });
      } else {
        toast.error(<h6>{error.message}</h6>, { autoClose: 4000 });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isComponentMounted.current) {
      fetchData(url);
    }

    return () => {
      isComponentMounted.current = false;
    };
  }, [url, isComponentMounted]);

  const triggerFetch = (newUrl, options = {}, onSuccess, onError) => {
    fetchData(newUrl, options, onSuccess, onError);
  };

  return { loading, data, triggerFetch };
};

export default useFetch;
