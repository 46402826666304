import React, { useState } from "react";
import { toast } from "react-toastify";
import config from "../config/config";
import DecodeToken from "../utilitaires/Token/DecodeToken";
import saveToken from "./localStorage/saveToken";

const useFetchLoginAtelier = () => {
  const apiBaseAuthentificationUrl = config.authentificationUrl;
  const [loading, setLoading] = useState(true);

  const fetchLoginAtelier = async (qrcode, navigate) => {
    setLoading(true);

    try {
      const response = await fetch(
        apiBaseAuthentificationUrl + "/login-atelier",
        {
          method: "POST",
          body: JSON.stringify({
            qrcode: qrcode,
          }),
        }
      );

      if (!response.ok) {
        if (
          response.headers.get("content-type")?.includes("application/json")
        ) {
          const errorJson = await response.json();
          throw new Error(errorJson.message);
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }

      const data = await response.json();
      const token = data;
      saveToken("token", token);
      const decodedToken = DecodeToken(token);
      toast.success(
        <>
          Bienvenue <bold>{decodedToken.nom}</bold>
        </>
      );
      navigate("/");
    } catch (error) {
      toast.error(<>{error.message}</>);
    } finally {
      setLoading(false);
    }
  };

  return { fetchLoginAtelier, loading };
};

export default useFetchLoginAtelier;
