import { Col } from 'react-bootstrap';
import './Wrapper.css'
import React from 'react';


const Wrapper = ({children, xxl, xl, lg, md, sm, xs, className}) => {
    return (
        <>
            <Col className={className + ' wrapper'}>
                {children}
            </Col>
        </> 
    );
};

export default Wrapper;