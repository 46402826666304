import "./AuthentificationUtilisateur.css";
import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation, Outlet } from "react-router-dom";

const AuthentificationUtilisateur = () => {
    const location = useLocation();

    return (
        <>
            <AnimatePresence mode="wait">
                <motion.div
                    key={location.pathname}
                    initial={{y: -8, opacity: 0}}
                    animate={{y: 0, opacity: 1}}
                    exit={{y: -8, opacity: 0}}
                    transition={{ ease: "easeInOut", duration: 0.5, delay: 0.2}}
                >
                    <Outlet/>
                </motion.div>
            </AnimatePresence>
        </>
    );
}
export default AuthentificationUtilisateur;