import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import AlignBox from '../AlignBox/AlignBox';

const NavLinkItem = ({ to, icon, text, onClick}) => {
  
  return (
    <Nav.Link as={ to && NavLink } to={ to && to } onClick={ onClick && (() => onClick())}>
      <AlignBox>
      {icon && icon}
      <div className="ms-1">{text}</div>
      </AlignBox>
    </Nav.Link>
  );
};
export default NavLinkItem;