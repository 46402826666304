import { motion } from "framer-motion";
import React, { createContext, useState } from "react";
import { PiChartBar, PiHouse, PiPlus } from "react-icons/pi";
import { Outlet, useLocation } from "react-router-dom";
import SecondNavBar from "../../components/SecondNavBar/SecondNavBar";

export const FreeSecondNavBarContext = createContext();

const ServiceApresVente = () => {
  const location = useLocation();
  const [freeRef, setFreeRef] = useState(null);

  const handleFreeSecondNavBarRef = (freeSecondNavBarRef) => {
    setFreeRef(freeSecondNavBarRef.current);
  };

  const links = [
    {
      to: "tableau_de_bord",
      icon: <PiHouse size={"1.1em"} />,
      text: "Tableau de bord",
    },
    {
      to: "nouvelle_demande",
      icon: <PiPlus size={"1.1em"} />,
      text: "Nouvelle demande",
    },
    {
      to: "statistiques",
      icon: <PiChartBar size={"1.1em"} />,
      text: "Statistiques",
    },
  ];

  return (
    <FreeSecondNavBarContext.Provider value={freeRef}>
      <SecondNavBar links={links} setRef={handleFreeSecondNavBarRef} />
      <motion.div
        key={location.pathname}
        initial={{ y: 25, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -25, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <Outlet />
      </motion.div>
    </FreeSecondNavBarContext.Provider>
  );
};

export default ServiceApresVente;
