import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { Col } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import IconBarre from "../../../components/CustomIcons/IconBarre/IconBarre";
import PosteIcon from "../../../components/CustomIcons/PosteIcon/PosteIcon";
import "./ScanComponents.css";

const ScanProduct = ({
  product,
  className,
  message = "attente scan \n cadre...",
  icon = <PosteIcon tache="scan_product" size={70 + "%"} />,
}) => {
  const dataProduct = product && product.data;

  return (
    <AnimatePresence mode="wait">
      {!dataProduct ? (
        <motion.div
          className="h-100"
          key="default"
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ y: -50, opacity: 0 }}
          transition={{ duration: 0.25, ease: [0.17, 0.67, 0.83, 0.67] }}
        >
          <Col
            xs={12}
            className={
              "bg-perform text-black text-uppercase h2 fw-bold rounded justify-content-center align-items-center text-center h-100 " +
              className
            }
          >
            {icon}
            <div>{message}</div>
          </Col>
        </motion.div>
      ) : (
        <motion.div
          className="h-100"
          key="success"
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ y: -50, opacity: 0 }}
          transition={{ duration: 0.25, ease: [0.17, 0.67, 0.83, 0.67] }}
        >
          <Col
            xs={12}
            className={
              className + " bg-hybride rounded h-100 position-relative "
            }
          >
            <div className="text-uppercase w-100 h3 pt-4 text-center">
              <PosteIcon tache="scan_product" size={25 + "%"} />
              <br />
              <br />
              Produit scanné
            </div>
            <div className="card h4 m-4 p-3">
              <p>
                Commande:{" "}
                {parseInt(dataProduct.cmd) + "." + dataProduct.extension}
              </p>
              <p>Position: {parseInt(dataProduct.position)}</p>
              <p>Repère quantité: {parseInt(dataProduct.repere_quantite)}</p>
              <p>
                Elément:
                {parseInt(dataProduct.element) === 0
                  ? " Dormant"
                  : " Ouvrant " + parseInt(dataProduct.element)}
              </p>
              <p>
                <IconBarre barre={dataProduct.barre} size={50} />
              </p>
              <p>N° de série: {dataProduct.serie}</p>
            </div>
            <div className="text-center position-absolute bottom-0 w-100 mb-4">
              <FaCheckCircle size={20 + "%"} />
            </div>
          </Col>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ScanProduct;
