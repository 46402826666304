import './Statistiques.css'
import React from 'react';

const Statistiques = () => {

  return (

    <>
      Ici c'est les statistiques
    </>

  );
};

export default Statistiques;