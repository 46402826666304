import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useDefaultRoute = (userDefaultRoute) => {
  const location = useLocation();

  useEffect(() => {
    sessionStorage.setItem("lastVisitedRoute", location.pathname);
  }, [location]);

  const lastVisitedRoute = sessionStorage.getItem("lastVisitedRoute");

  return lastVisitedRoute === "/" ? "/" + userDefaultRoute : lastVisitedRoute;
};

export default useDefaultRoute;
