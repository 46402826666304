function FormatString(inputString) {
  // Convertir en minuscules
  let lowercaseString = inputString.toLowerCase();

  // Supprimer les accents
  lowercaseString = lowercaseString
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

  // Remplacer les espaces par des underscores
  lowercaseString = lowercaseString.replace(/\s+/g, "_");

  return lowercaseString;
}

export default FormatString;
