import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { PiFactoryFill, PiUserFill } from "react-icons/pi";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./Auth.css";

const Auth = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleTabSelect = (key) => {
    // Changer la route en fonction de l'onglet sélectionné
    if (key === "utilisateur") {
      navigate("/auth/utilisateur/login");
    } else if (key === "atelier") {
      navigate("/auth/atelier");
    }
  };

  useEffect(() => {
    handleTabSelect("utilisateur");
  }, []);

  return (
    <>
      <motion.div
        initial={{ y: -900 }}
        animate={{ y: 0 }}
        exit={{ y: 100 }}
        transition={{ ease: "easeInOut", duration: 0.4 }}
      >
        <div className="authentification-wrapper align-items-center justify-content-center d-flex radius-top-left">
          <motion.div layout>
            <Tabs
              defaultActiveKey="utilisateur"
              justify
              onSelect={handleTabSelect}
            >
              <Tab
                eventKey="utilisateur"
                title={
                  <>
                    <PiUserFill className="me-2" />
                    Utilisateur
                  </>
                }
                className=""
              >
                <motion.div
                  key={location.pathname}
                  initial={{ y: -8, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -8, opacity: 0 }}
                  transition={{ ease: "easeInOut", duration: 0.5, delay: 0.2 }}
                >
                  <Outlet />
                </motion.div>
              </Tab>
              <Tab
                eventKey="atelier"
                title={
                  <>
                    <PiFactoryFill className="me-2" />
                    Atelier
                  </>
                }
              >
                <motion.div
                  key={location.pathname}
                  initial={{ y: -8, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -8, opacity: 0 }}
                  transition={{ ease: "easeInOut", duration: 0.5, delay: 0.2 }}
                >
                  <Outlet />
                </motion.div>
              </Tab>
            </Tabs>
          </motion.div>
        </div>
      </motion.div>
    </>
  );
};
export default Auth;
