import './SwitchView.css';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { PiRows, PiCardholder } from "react-icons/pi";
import AlignBox from '../../AlignBox/AlignBox';

const SwitchView = ({onChange, initViewMode}) => {
    const initialSwitchState = initViewMode === "card";

    const [viewMode, setViewMode]  = useState(initialSwitchState);

    const handleSwitchChange = () => {
        setViewMode(!viewMode);
        onChange(!viewMode);
    };

    return (
        <AlignBox className='me-3'>
        <PiRows size={20} className='text-secondary me-2'/>
        <Form>
            <Form.Check
                className='switch-view'
                type="switch"
                onChange={handleSwitchChange}
                checked={viewMode}
            />
        </Form>
        <PiCardholder size={20} className='text-secondary'/>
        </AlignBox>
    );
};

export default SwitchView;