import React from "react";
import { Col, Row, Stack } from "react-bootstrap";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./CustomTimeline.css";

const CustomTimelineElement = ({
  children,
  iconTitle,
  title,
  subTitle,
  titleClassName,
  ...props
}) => {
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      {...props}
    >
      <Stack direction="horizontal" className={titleClassName}>
        <div>{iconTitle}</div>
        <div>
          <h5 className="vertical-timeline-element-title">{title}</h5>
          <h6 className="vertical-timeline-element-subtitle Roboto-Light">
            {subTitle}
          </h6>
        </div>
      </Stack>
      <h6 className="description Roboto-Regular text-secondary mt-3">
        {children}
      </h6>
    </VerticalTimelineElement>
  );
};

const CustomTimeline = ({ children, className, ...props }) => {
  return (
    <Row className="m-0">
      <Col xl={4}></Col>
      <Col xl={5}>
        <VerticalTimeline
          className={"custom-timeline " + className}
          layout="1-column-left"
          {...props}
        >
          {children}
        </VerticalTimeline>
      </Col>
      <Col xl={3}></Col>
    </Row>
  );
};

CustomTimeline.Element = CustomTimelineElement;

export default CustomTimeline;
