import React from "react";
import { Dropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./Dropdown.css";

const DropdownSecondNavBar = ({ links, libelle, icon }) => {
  return (
    <Dropdown className="dropdown-second-nav-bar">
      <Dropdown.Toggle id="dropdown-basic">
        {icon} {libelle}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {links.map((link, linkIndex) => {
          return (
            <Dropdown.Item key={linkIndex}>
              <NavLink to={link.to} className="Roboto-Light">
                {link.icon && <span className="me-3">{link.icon}</span>}{" "}
                {link.text}
              </NavLink>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownSecondNavBar;
