import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import Auth from "./authentification/Auth";
import AuthentificationAtelier from "./authentification/AuthentificationAtelier/AuthentificationAtelier";
import AuthentificationUtilisateur from "./authentification/AuthentificationUtilisateur/AuthentificationUtilisateur";
import Forgot from "./authentification/AuthentificationUtilisateur/Forgot/Forgot";
import Login from "./authentification/AuthentificationUtilisateur/Login/Login";
import Register from "./authentification/AuthentificationUtilisateur/Register/Register";
import "./custom.scss";
import "./customVariablesToastify.scss";
import "./fonts.css";
import "./index.css";
import Administration from "./pages/administration/Administration";
import TableauDeBordAdministration from "./pages/administration/TableauDeBordAdministration/TableauDeBordAdministration";
import UtilisateurClient from "./pages/administration/UtilisateurClient/UtilisateurClient";
import UtilisateurProferm from "./pages/administration/UtilisateurProferm/UtilisateurProferm";
import Approvisionnement from "./pages/approvisionnement/Approvisionnement";
import Atelier from "./pages/atelier/Atelier";
import Clients from "./pages/clients/Clients";
import NouveauClient from "./pages/clients/NouveauClient/NouveauClient";
import TableauDeBordClients from "./pages/clients/TableauDeBordClients/TableauDeBordClients";
import Commandes from "./pages/commandes/Commandes";
import Logistique from "./pages/logistique/Logistique";
import Operateurs from "./pages/production/Operateurs/Operateurs";
import Postes from "./pages/production/Postes/Postes";
import Production from "./pages/production/Production";
import Produits from "./pages/production/Produits/Produits";
import StatistiquesProduction from "./pages/production/StatistiquesProduction/StatistiquesProduction";
import Stockages from "./pages/production/Stockages/Stockages";
import TableauDeBordProduction from "./pages/production/TableauDeBordProduction/TableauDeBordProduction";
import NouvelleDemande from "./pages/service_apres_vente/NouvelleDemande/NouvelleDemande";
import ServiceApresVentes from "./pages/service_apres_vente/ServiceApresVente";
import Statistiques from "./pages/service_apres_vente/Statistiques/Statistiques";
import TableauDeBordServiceApresVente from "./pages/service_apres_vente/TableauDeBordServiceApresVente/TableauDeBordServiceApresVente";

const router = createBrowserRouter([
  {
    path: "/auth",
    element: <Auth />,
    children: [
      {
        index: true,
        element: <Navigate to="utilisateur/login" />,
      },
      {
        path: "utilisateur",
        element: <AuthentificationUtilisateur />,
        children: [
          {
            index: true,
            element: <Login />,
          },
          {
            path: "login",
            element: <Login />,
          },
          {
            path: "register",
            element: <Register />,
          },
          {
            path: "forgot",
            element: <Forgot />,
          },
        ],
      },
      {
        path: "atelier",
        element: <AuthentificationAtelier />,
      },
    ],
  },
  {
    path: "/",
    element: <App />,
    children: [
      {
        //COMMANDES
        path: "commandes",
        element: <Commandes />,
      },
      {
        //APPROVISIONNEMENT
        path: "approvisionnement",
        element: <Approvisionnement />,
      },
      {
        //PRODUCTION
        path: "production",
        element: <Production />,
        children: [
          { index: true, element: <Produits /> },
          {
            path: "tableau_de_bord",
            element: <TableauDeBordProduction />,
          },
          {
            path: "produits",
            element: <Produits />,
          },
          {
            path: "operateurs",
            element: <Operateurs />,
          },
          {
            path: "postes",
            element: <Postes />,
          },
          {
            path: "stockages",
            element: <Stockages />,
          },
          {
            path: "statistiques",
            element: <StatistiquesProduction />,
          },
        ],
      },
      {
        //SERVICE APRES-VENTE
        path: "service_apres_vente",
        element: <ServiceApresVentes />,
        children: [
          { index: true, element: <TableauDeBordServiceApresVente /> },
          {
            path: "tableau_de_bord",
            element: <TableauDeBordServiceApresVente />,
          },
          {
            path: "nouvelle_demande",
            element: <NouvelleDemande />,
          },
          {
            path: "statistiques",
            element: <Statistiques />,
          },
        ],
      },
      {
        //LOGISTIQUE
        path: "logistique",
        element: <Logistique />,
      },
      {
        //CLIENTS
        path: "clients",
        element: <Clients />,
        children: [
          { index: true, element: <Clients /> },
          {
            path: "tableau_de_bord",
            element: <TableauDeBordClients />,
          },
          {
            path: "nouveau_client",
            element: <NouveauClient />,
          },
        ],
      },
      {
        //ADMINISTRATION
        path: "administration",
        element: <Administration />,
        children: [
          {
            index: true,
            path: "tableau_de_bord",
            element: <TableauDeBordAdministration />,
          },
          {
            path: "utilisateur_client",
            element: <UtilisateurClient />,
          },
          {
            path: "utilisateur_proferm",
            element: <UtilisateurProferm />,
          },
        ],
      },
      {
        path: "atelier",
        element: <Atelier />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <RouterProvider router={router} />
    <ToastContainer
      position="bottom-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
      theme="light"
      width="100%"
    />
  </>
);
