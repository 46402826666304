import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import NavLinkItem from "../NavLinkItem/NavLinkItem";
import DropdownSecondNavBar from "./Dropdown/Dropdown";
import "./SecondNavBar.css";

const SecondNavBar = React.forwardRef((props, ref) => {
  const containerRef = useRef(null);
  const location = useLocation();
  const [prevPathname, setPrevPathname] = useState(location.pathname);
  const currentMainPath = location.pathname.split("/")[1];

  useEffect(() => {
    if (props.setRef) {
      props.setRef(containerRef);
    }
  }, [props.setRef, containerRef]);

  return (
    <motion.div
      key={currentMainPath}
      initial={{ opacity: 0, y: -25 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -25 }}
    >
      <Navbar expand="sm" className="secondary-navbar" data-bs-theme="light">
        <Navbar.Collapse id="basic-navbar-nav" className="Roboto-Regular m-0">
          <Nav className="me-auto m-0 ms-2 mt-1 mb-1">
            {props.links.map((link, linkIndex) => {
              return (
                <>
                  {link.type === "link" ? (
                    <NavLinkItem
                      className="h-100"
                      key={linkIndex}
                      to={link.to}
                      icon={link.icon}
                      text={link.text}
                    />
                  ) : (
                    link.type === "dropdown" && (
                      <DropdownSecondNavBar
                        links={link.links}
                        libelle={link.text}
                        icon={link.icon}
                      />
                    )
                  )}
                </>
              );
            })}
          </Nav>
          <Nav className="ms-auto me-2 mt-1">
            <div
              className="d-flex  align-items-center"
              ref={containerRef}
            ></div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </motion.div>
  );
});

export default SecondNavBar;
