import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
  usePDF,
} from "@react-pdf/renderer";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { PiPrinter } from "react-icons/pi";
import FormatString from "../../../utilitaires/FormatString/FormatString";
import QRCodeBase64 from "../../QRCodeBase64/QRCodeBase64";

const QRCodePDF = ({
  qrCodeData,
  title,
  onRender,
  variant,
  text,
  buttonSize,
}) => {
  const styles = StyleSheet.create({
    page: {
      padding: 10,
    },
    container: {
      flexDirection: "row", // Pour afficher les éléments côte à côte
      border: "1pt solid black", // Bordure de 1pt, couleur noire
      padding: 8, // Espace intérieur de 8 points
      marginBottom: 10, // Marge inférieure de 10 points
    },
    imageContainer: {
      marginRight: 10, // Marge à droite de l'image
    },
    image: {
      height: 150,
      width: 150,
    },
    text: {
      flex: 1, // Le texte prend l'espace disponible
    },
  });

  const handleRenderComplete = () => {
    if (onRender) {
      onRender();
    }
  };

  const document = (
    <Document onRender={() => handleRenderComplete()}>
      <Page style={styles.page}>
        <View style={styles.container}>
          <View style={styles.imageContainer}>
            <Image src={QRCodeBase64(qrCodeData)} style={styles.image}></Image>
          </View>
          <View style={styles.text}>
            <Text>{title}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );

  const [instance, updateInstance] = usePDF({ document: document });

  useEffect(() => {
    if (instance && instance.progress === 100 && onRender) {
      onRender();
    }
  }, [instance, onRender]);

  return (
    <Button
      variant={variant}
      className="text-white Roboto-Light"
      href={instance.url}
      as="a"
      download={FormatString(title) + ".pdf"}
      size={buttonSize}
    >
      <PiPrinter size={18} className={text && "me-2"} />
      {text}
    </Button>
  );
};

export default QRCodePDF;
