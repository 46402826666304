import React from 'react';

const TableauDeBordClients = () => {

  return (

    <>
      Ici c'est le tableau de bord des clients
    </>

  );
};

export default TableauDeBordClients;