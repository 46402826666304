import { motion } from "framer-motion";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import useDefaultRoute from "../../services/useDefaultRoute";
import PfIconPolygon from "../CustomIcons/PfIconPolygon/PfIconPolygon";
import NavLinkItem from "../NavLinkItem/NavLinkItem";
import ButtonFullscreen from "./ButtonFullscreen/ButtonFullscreen";
import LinkIcon from "./LinkIcon/LinkIcon";
import "./TopNavbar.css";
import UserProfilDropdown from "./UserProfilDropdown/UserProfilDropdown";

const TopNavbar = React.forwardRef((props, ref) => {
  const userData = useContext(UserContext);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const [groupeDefaultRoute, setGroupeDefaultRoute] = useState(
    userData.utilisateur &&
      "/" + userData.utilisateur.groupe.routeDefault.chemin
  );
  const defaultRoute = useDefaultRoute(
    userData.utilisateur && userData.utilisateur.groupe.routeDefault.chemin
  );

  useLayoutEffect(() => {
    if (containerRef.current && ref) {
      ref(containerRef.current.clientHeight);
    }
  }, [ref]);

  useEffect(() => {
    if (userData.utilisateur) navigate(defaultRoute);
  }, [defaultRoute]);

  return (
    <motion.div
      key="topNavbar"
      initial={{ opacity: 0, y: -30 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
    >
      <Navbar
        expand="sm"
        className="bg-body-proferm top-navbar"
        data-bs-theme="light"
        ref={containerRef}
      >
        <LinkContainer
          to={
            userData.utilisateur
              ? userData.utilisateur.groupe.routeDefault.chemin
              : "/"
          }
        >
          <Navbar.Brand>
            <PfIconPolygon size={1.1} />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Collapse id="basic-navbar-nav" className="Roboto-Regular ms-1">
          <Nav className="me-auto m-0">
            {userData &&
              userData.groupeRouteActions &&
              userData.groupeRouteActions.map((link, linkIndex) => {
                const route = link.route;
                return (
                  <NavLinkItem
                    key={linkIndex}
                    to={"/" + route.chemin}
                    text={route.libelle}
                    icon={
                      route.icon && (
                        <LinkIcon iconName={route.icon} iconSize={13} />
                      )
                    }
                  />
                );
              })}
          </Nav>
          <Nav className="ms-auto m-0">
            <ButtonFullscreen />
            <UserProfilDropdown />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </motion.div>
  );
});

export default TopNavbar;
