import "./ButtonFullscreen.css"
import React, { useState, useEffect } from 'react';
import { PiArrowsOut, PiArrowsIn } from "react-icons/pi";
import screenfull from 'screenfull';


const ButtonFullscreen = () => {
    const [isFullscreen, setIsFullscreen] = useState(screenfull.isFullscreen);

    const toggleFullscreen = () => {
        if (screenfull.isEnabled) {
            screenfull.toggle();
        }
        console.log('screenfull :>> ', screenfull);
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(screenfull.isFullscreen);
        };
    
        screenfull.on('change', handleFullscreenChange);
    
        return () => {
            screenfull.off('change', handleFullscreenChange);
        };
    }, []);

    return (
        <button className="button-fullscreen" onClick={toggleFullscreen}>
            {screenfull.isFullscreen ? <PiArrowsIn size={15}/> : <PiArrowsOut size={15}/>}
        </button>
    );
};

export default ButtonFullscreen;