import { useEffect, useState } from "react";

export function useBodyHeight(heightAdjust = 0) {
  const [bodyHeight, setBodyHeight] = useState(
    window.innerHeight - 36 - 30 - heightAdjust
  );

  useEffect(() => {
    const handleResize = () => {
      setBodyHeight(window.innerHeight - 36 - 30 - heightAdjust);
    };

    window.addEventListener("resize", handleResize);

    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Exécuter une fois lors du montage du composant

  return bodyHeight;
}
