import React from "react";
import { Button } from "react-bootstrap";
import "./BoutonAtelierMenu.css";

const BoutonAtelierMenu = ({ children, icon, onClick }) => {
  return (
    <Button
      variant="outline-secondary"
      className="bouton-atelier-menu rounded h-100 w-100"
      onClick={onClick}
    >
      <div>{icon}</div>
      <div className="mt-3 fw-bolder h5 d-none d-md-block">{children}</div>
    </Button>
  );
};

export default BoutonAtelierMenu;
