import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { PiPlusCircleThin } from "react-icons/pi";
import { FooterContext } from "../../../App";
import AlignBox from "../../../components/AlignBox/AlignBox";
import StockageTypeIcon from "../../../components/CustomIcons/StockageTypeIcon/StockageTypeIcon";
import ResizableTable from "../../../components/ResizableTable/ResizableTable";
import ToolNavBar from "../../../components/ToolNavBar/ToolNavBar";
import config from "../../../config/config";
import { FreeSecondNavBarContext } from "../Production";
import ModalNouveauStockage from "./ModalNouveauStockage/ModalNouveauStockage";
import ModalStockage from "./ModalStockage/ModalStockage";

function Stockage() {
  const [showNouveauStockage, setShowNouveauStockage] = useState(false);
  const [showStockage, setShowStockage] = useState(false);
  const [idStockage, setIdStockage] = useState(null);
  const searchBoxRefContext = useContext(FreeSecondNavBarContext);
  const footerRefs = useContext(FooterContext);
  const apiUrl = config.apiUrl;
  const resizableTableRef = useRef();

  const handleShowNouveauStockageModal = () => setShowNouveauStockage(true);
  const handleHideNouveauStockageModal = () => {
    resizableTableRef.current.refreshTable();
    setShowNouveauStockage(false);
  };

  const handleShowStockageModal = (idStockage) => {
    setIdStockage(idStockage);
    setShowStockage(true);
  };
  const handleHideStockageModal = () => {
    resizableTableRef.current.refreshTable();
    setShowStockage(false);
  };

  const tableConfig = [
    {
      nom: "Nom",
      render: (row) => (
        <AlignBox>
          <StockageTypeIcon
            idStockageType={row.type.id}
            size={2}
            className="me-2"
            reverse
          />
          {row.nom}
        </AlignBox>
      ),
      width: 240,
    },
    {
      nom: "ID",
      render: (row) => row.id,
      width: 120,
      center: true,
    },
    {
      nom: "Type",
      render: (row) => row.type.libelle,
      width: 80,
    },
    {
      nom: "Capacité",
      render: (row) => <>{row.capacite} places</>,
      width: 80,
    },
    {
      nom: "Création",
      render: (row) => row.creation,
      width: 120,
      center: true,
    },
  ];

  const toolBarConfig = [
    {
      text: "Nouveau stockage",
      icon: <PiPlusCircleThin size={20} />,
      onClick: handleShowNouveauStockageModal,
    },
  ];

  const cardConfig = {
    header: (row) => row.nom,
    headerClass: () => "bg-perform text-black",
    icon: (row) => (
      <StockageTypeIcon
        idStockageType={row.type.id}
        size={3.4}
        className="mt-2"
        reverse
      />
    ),
    title: (row) => <>{row.type.libelle}</>,
    text: (row) => (
      <>
        id: {row.id} <br />
        creation: {row.creation} <br />
        capacite: {row.capacite} places
      </>
    ),
  };

  return (
    <>
      <ModalNouveauStockage
        show={showNouveauStockage}
        handleClose={handleHideNouveauStockageModal}
      ></ModalNouveauStockage>
      <ModalStockage
        show={showStockage}
        handleClose={handleHideStockageModal}
        idStockage={idStockage}
      ></ModalStockage>
      <ToolNavBar config={toolBarConfig} />
      <Container fluid className="ps-0">
        <Row>
          <Col>
            <Card className="border-0">
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0, y: 25 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <ResizableTable
                    ref={resizableTableRef}
                    urlFetch={apiUrl + "/stockage-datatable"}
                    limit={50}
                    indexOrder="stockage_nom"
                    viewModeInit="card"
                    indexSort={0}
                    sortInit="ASC"
                    heightAdjust={82}
                    searchInputRef={searchBoxRefContext}
                    paginationRef={footerRefs && footerRefs.middle}
                    filterResultRef={footerRefs && footerRefs.start}
                    totalRef={footerRefs && footerRefs.end}
                    TableConfig={tableConfig}
                    switchViewRef={searchBoxRefContext}
                    tableConfig={tableConfig}
                    cardConfig={cardConfig}
                    rowClick={handleShowStockageModal}
                  />
                </motion.div>
              </AnimatePresence>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Stockage;
