import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { PiPlusCircleThin } from "react-icons/pi";
import { FooterContext } from "../../../App";
import OperateurGroupeIcon from "../../../components/CustomIcons/OperateurGroupeIcon/OperateurGroupeIcon";
import ResizableTable from "../../../components/ResizableTable/ResizableTable";
import ToolNavBar from "../../../components/ToolNavBar/ToolNavBar";
import config from "../../../config/config";
import { FreeSecondNavBarContext } from "../Production";
import ModalNouvelOperateur from "./ModalNouvelOperateur/ModalNouvelOperateur";
import ModalOperateur from "./ModalOperateur/ModalOperateur";

function Operateur() {
  const [showNouvelOperateur, setShowNouvelOperateur] = useState(false);
  const [showOperateur, setShowOperateur] = useState(false);
  const [idOperateur, setIdOperateur] = useState(null);
  const searchBoxRefContext = useContext(FreeSecondNavBarContext);
  const footerRefs = useContext(FooterContext);
  const apiUrl = config.apiUrl;
  const resizableTableRef = useRef();
  const handleShowNouvelOperateurModal = () => setShowNouvelOperateur(true);
  const handleHideNouvelOperateurModal = () => {
    resizableTableRef.current.refreshTable();
    setShowNouvelOperateur(false);
  };

  const handleShowOperateurModal = (idOperateur) => {
    setIdOperateur(idOperateur);
    setShowOperateur(true);
  };
  const handleHideOperateurModal = () => {
    resizableTableRef.current.refreshTable();
    setIdOperateur(null);
    setShowOperateur(false);
  };

  const tableConfig = [
    {
      nom: "Groupe",
      render: (row) => (
        <>
          <OperateurGroupeIcon
            idOperateurGroupe={row.groupe.id}
            size={3}
            className="me-2"
          />
          {row.groupe.libelle}
        </>
      ),
      width: 80,
    },
    {
      nom: "Prénom Nom",
      render: (row) => (
        <>
          {row.prenom} {row.nom}
        </>
      ),
      width: 240,
    },
    {
      nom: "Création",
      render: (row) => row.creation,
      width: 120,
      center: true,
    },
    {
      nom: "statut",
      render: (row) => row.statut.libelle,
      width: 180,
      center: true,
    },
  ];

  const toolBarConfig = [
    {
      text: "Nouvel opérateur",
      icon: <PiPlusCircleThin size={20} />,
      onClick: handleShowNouvelOperateurModal,
    },
  ];

  const cardConfig = {
    header: (row) => (
      <>
        {row.prenom} {row.nom}
      </>
    ),
    icon: (row) => (
      <OperateurGroupeIcon
        idOperateurGroupe={row.groupe.id}
        size={3}
        className="mt-2"
      />
    ),
    headerClass: () => "bg-textural text-white",
    title: (row) => <>{row.groupe.libelle}</>,
    text: (row) => (
      <>
        id: {row.id} <br />
        etat: {row.statut.libelle}
        <br />
        creation: {row.creation}
      </>
    ),
  };

  return (
    <>
      <ModalNouvelOperateur
        show={showNouvelOperateur}
        handleClose={handleHideNouvelOperateurModal}
      ></ModalNouvelOperateur>
      <ModalOperateur
        show={showOperateur}
        handleClose={handleHideOperateurModal}
        idOperateur={idOperateur}
      ></ModalOperateur>
      <ToolNavBar config={toolBarConfig} />
      <Container fluid className="ps-0">
        <Row>
          <Col>
            <Card className="border-0">
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0, y: 25 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <ResizableTable
                    ref={resizableTableRef}
                    urlFetch={apiUrl + "/operateur-datatable"}
                    limit={50}
                    indexOrder="operateur_creation"
                    viewModeInit="card"
                    indexSort={2}
                    sortInit="DESC"
                    heightAdjust={82}
                    searchInputRef={searchBoxRefContext}
                    paginationRef={footerRefs && footerRefs.middle}
                    filterResultRef={footerRefs && footerRefs.start}
                    totalRef={footerRefs && footerRefs.end}
                    TableConfig={tableConfig}
                    switchViewRef={searchBoxRefContext}
                    tableConfig={tableConfig}
                    cardConfig={cardConfig}
                    rowClick={handleShowOperateurModal}
                  />
                </motion.div>
              </AnimatePresence>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Operateur;
