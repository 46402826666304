import { useRef } from "react";
import config from "../../config/config";
import useFetch from "../../services/useFetch";

const UserData = () => {
  const apiUrl = config.apiUrl;
  const urlUserData = apiUrl + "/utilisateur-interne-me";
  const isComponentMounted = useRef(true);
  const { data: userData } = useFetch(
    urlUserData,
    "GET",
    isComponentMounted,
    []
  );

  return userData;
};

export default UserData;
