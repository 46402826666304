import React from "react";
import { Card } from "react-bootstrap";
import "./DashboardCard.css";

const DashboardCard = ({ children, theme, className }) => {
  return (
    <>
      <Card
        className={
          (theme === "light"
            ? "dashboard-card-light "
            : "dashboard-card-dark ") + className
        }
      >
        <Card.Body>{children}</Card.Body>
      </Card>
    </>
  );
};

export default DashboardCard;
