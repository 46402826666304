import { motion } from "framer-motion";
import React from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import "./EyePassword.css";

const EyePassword = (props) => {
  const handleClickVisible = () => {
    props.handleClick();
  };

  return (
    <motion.div
      whileHover={{ scale: 1.1 }}
      transition={{ type: "spring", stiffness: 400, damping: 10 }}
      onClick={handleClickVisible}
      className="position-absolute eye z-10 end-0 text-secondary"
      style={
        props.style
          ? props.style
          : { marginTop: 15 + "px", marginRight: 13 + "px" }
      }
    >
      {props.passwordVisible ? <BsEyeSlash size={25} /> : <BsEye size={25} />}
    </motion.div>
  );
};

export default EyePassword;
