import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import Table from "react-bootstrap/Table";
import { createPortal } from "react-dom";
import { useBodyHeight } from "../../services/useBodyHeight";
import { useContainer } from "../../services/useContainer";
import useFetch from "../../services/useFetch";
import Loader from "../Loader/Loader";
import InputSearchBar from "../inputs/InputSearchBar/InputSearchBar";
import CardView from "./CardView/CardView";
import Pagination from "./Pagination/Pagination";
import RTNoResult from "./RTNoResult/RTNoResult";
import "./ResizableTable.css";
import SwitchView from "./SwitchView/SwitchView";
import SortArrow from "./sortArrow/sortArrow";

const ResizableTable = React.forwardRef(
  (
    {
      urlFetch,
      indexOrder,
      indexSort,
      sortInit,
      heightAdjust,
      limit,
      tableConfig,
      searchInputRef,
      switchViewRef,
      paginationRef,
      filterResultRef,
      totalRef,
      cardConfig,
      viewModeInit,
      rowClick,
      autoRefresh,
    },
    ref
  ) => {
    const tableHeight = useBodyHeight(heightAdjust);
    const [newUrl, setNewUrl] = useState(
      urlFetch + "/" + indexOrder + "/" + sortInit + "/" + limit
    );
    const [indexColumn, setIndexColumn] = useState(indexSort);
    const [queryParameters, setQueryParameters] = useState({
      order: indexOrder,
      sort: sortInit,
      limit: limit ? limit : 50,
      searchValue: "",
      currentPage: 1,
    });
    const [viewMode, setViewMode] = useState(viewModeInit);
    const [timerId, setTimerId] = useState(null);
    const [reloadTimer, setReloadTimer] = useState(autoRefresh);
    const theadRef = useRef(null);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const tableContainerRef = useRef(null);
    const isComponentMounted = useRef(true);
    const [filter, setFilter] = useState("aucun");
    const containerPagination = useContainer(paginationRef && paginationRef);
    const containerFilterResult = useContainer(
      filterResultRef && filterResultRef
    );
    const containerTotal = useContainer(totalRef && totalRef);
    const containerSwitchView = useContainer(switchViewRef && switchViewRef);
    const containerSearchBar = useContainer(searchInputRef && searchInputRef);

    const refreshTable = () => {
      triggerFetch(newUrl);
    };

    const handleSort = (sortKey, newIndexColumn) => {
      isComponentMounted.current = true;
      setQueryParameters((prevQueryParameters) => ({
        ...prevQueryParameters,
        order: sortKey,
        sort: prevQueryParameters.sort === "ASC" ? "DESC" : "ASC",
      }));
      setIndexColumn(newIndexColumn);
    };

    const handleSearch = (InputSearchValue) => {
      if (timerId) {
        clearTimeout(timerId);
      }

      const newTimerId = setTimeout(() => {
        setQueryParameters((prevQueryParameters) => ({
          ...prevQueryParameters,
          searchValue: InputSearchValue,
        }));
        if (InputSearchValue !== "") setFilter("'" + InputSearchValue + "'");
        else setFilter("aucun");
      }, 600);

      handlePage(1);
      setTimerId(newTimerId);
    };

    const handlePage = (page) => {
      setQueryParameters((prevQueryParameters) => ({
        ...prevQueryParameters,
        currentPage: page,
      }));
    };

    const handleViewMode = () => {
      setViewMode((prevViewMode) =>
        prevViewMode === "card" ? "table" : "card"
      );
    };

    const getNestedPropertyValue = (obj, key) => {
      const keys = key.split(".");
      return keys.reduce(
        (acc, currentKey) => (acc ? acc[currentKey] : undefined),
        obj
      );
    };

    // useFetch pour le chargement initial
    const { data, loading, triggerFetch } = useFetch(
      newUrl,
      "GET",
      isComponentMounted,
      []
    );

    useEffect(() => {
      if (isFirstRender) {
        setIsFirstRender(false);
        return;
      }

      isComponentMounted.current = true;
      const { order, sort, limit, searchValue, currentPage } = queryParameters;
      const newUrl = `${urlFetch}/${order}/${sort}/${limit}/${currentPage}/${searchValue}/`;
      setNewUrl(newUrl);
      triggerFetch(newUrl);
    }, [queryParameters]);

    useEffect(() => {
      if (autoRefresh) {
        const reload = setInterval(() => {
          setReloadTimer((reloadTimer) => {
            if (reloadTimer > 0) {
              return reloadTimer - 1;
            } else {
              console.log(newUrl);
              triggerFetch(newUrl);
              return autoRefresh;
            }
          });
        }, 1000);

        return () => clearInterval(reload);
      }
    }, [newUrl]);

    const rows = data.rows || {};
    const fields = data.fieldsNames;
    const countResult = data.rows && data.rows.length;
    const countFiltered = data.recordsFiltered;
    const countTotal = data.recordsTotal;

    useImperativeHandle(ref, () => ({
      refreshTable,
    }));

    return (
      <div
        className="conteneur-table"
        style={{ maxHeight: `${tableHeight}px` }}
        ref={tableContainerRef}
      >
        {containerFilterResult
          ? createPortal(
              <>
                Filtres: {filter}, Résultats filtrés: {countFiltered}
              </>,
              containerFilterResult
            )
          : null}
        {containerTotal
          ? createPortal(
              <>
                {autoRefresh && (
                  <>
                    Actualisation automatique dans : {reloadTimer}s
                    <span className="text-light-aluminium"> |</span>
                  </>
                )}{" "}
                Affichés: {countResult} / {countTotal}
              </>,
              containerTotal
            )
          : null}
        {containerPagination
          ? createPortal(
              <Pagination
                currentPageTable={queryParameters.currentPage}
                changePage={handlePage}
                countRows={countFiltered}
                limit={limit}
              />,
              containerPagination
            )
          : null}
        {containerSwitchView
          ? createPortal(
              <SwitchView
                onChange={handleViewMode}
                initViewMode={viewModeInit}
              />,
              containerSwitchView
            )
          : null}
        {containerSearchBar
          ? createPortal(
              <InputSearchBar onChange={handleSearch} />,
              containerSearchBar
            )
          : null}
        {loading ? (
          <Loader>Chargement de la liste des SAV en cours ...</Loader>
        ) : countResult > 0 ? (
          viewMode === "table" ? (
            <Table responsive="lg" className="table-fixe">
              <thead ref={theadRef}>
                <tr>
                  {tableConfig &&
                    tableConfig.map((thead, index) => {
                      return (
                        <td
                          key={fields[index]}
                          width={thead.width}
                          className={thead.center && "text-center"}
                          onClick={() => {
                            handleSort(fields[index], index);
                          }}
                        >
                          {thead.nom}
                          {indexColumn === index ? (
                            <SortArrow sort={queryParameters.sort} />
                          ) : null}
                        </td>
                      );
                    })}
                </tr>
              </thead>
              <tbody className="tableContainer">
                {rows &&
                  Object.keys(rows).map((rowKey, rowIndex) => {
                    const row = rows[rowKey];
                    return (
                      <tr
                        key={rowIndex}
                        onClick={rowClick && (() => rowClick(row.id))}
                      >
                        {tableConfig.map((config, colIndex) => {
                          return (
                            <td
                              key={`${rowIndex}-${colIndex}`}
                              width={config.width}
                              className={config.center && "text-center"}
                            >
                              {config.render
                                ? config.render(row)
                                : getNestedPropertyValue(row, config.key)}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          ) : (
            <CardView
              rows={rows}
              config={cardConfig}
              rowClick={rowClick && rowClick}
            />
          )
        ) : (
          <RTNoResult
            message={
              'Aucun resultat trouvé correspondant à "' +
              queryParameters.searchValue +
              '"'
            }
          />
        )}
      </div>
    );
  }
);

export default ResizableTable;
