import { yupResolver } from "@hookform/resolvers/yup";
import React, { useRef } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { PiPlusCircleThin } from "react-icons/pi";
import * as yup from "yup";
import AlignBox from "../../../../components/AlignBox/AlignBox";
import PosteTypeIcon from "../../../../components/CustomIcons/PosteTypeIcon/PosteTypeIcon";
import SimpleModal from "../../../../components/CustomModal/SimpleModal/SimpleModal";
import BasicInput from "../../../../components/inputs/BasicInput/BasicInput";
import FetchSelect from "../../../../components/inputs/FetchSelect/FetchSelect";
import config from "../../../../config/config";
import useFetch from "../../../../services/useFetch";

const schema = yup
  .object({
    type: yup.number().required("Type obligatoire").positive().integer(),

    nom: yup.string().required("Nom du poste obligatoire"),
  })
  .required();

const ModalNouveauPoste = ({ show, handleClose }) => {
  const apiUrl = config.apiUrl;
  const isComponentMounted = useRef(true);
  const { loading, data, triggerFetch } = useFetch(
    apiUrl,
    isComponentMounted,
    []
  );

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSuccess = () => {
    handleClose();
  };

  const onSubmit = (data) => {
    console.log("Données soumises:", data);
    triggerFetch(
      apiUrl + "/poste-create",
      {
        method: "POST",
        body: JSON.stringify(data),
      },
      onSuccess
    );
  };

  return (
    <SimpleModal
      show={show}
      handleClose={handleClose}
      iconTitle={<PiPlusCircleThin />}
      title={"Création d'un nouveau poste"}
      validationButtonText={"Enregistrer le poste"}
      onSubmit={handleSubmit(onSubmit)}
      onExited={() => reset()}
    >
      <Form noValidate>
        <FetchSelect
          urlOptions={apiUrl + "/poste-types"}
          labelText="Type de poste"
          onSelectChange={(selectedValue) => setValue("type", selectedValue)}
          placeholder="Selectionnez un type de poste"
          control={control}
          name="type"
          option={{
            value: "id",
            text: (row) => (
              <>
                <AlignBox>
                  <PosteTypeIcon
                    idPosteType={row.id}
                    size={3}
                    className="me-3"
                  />
                  {row.libelle}
                </AlignBox>
              </>
            ),
          }}
          setValue={setValue}
          errorMessage={errors.type?.message}
          registerFunction={register("type")}
        />
        <BasicInput
          labelText="Nom du poste"
          controlId="nom"
          control={control}
          name="nom"
          placeholder="Sélectionnez un type au dessus"
          errorMessage={errors.nom?.message}
          registerFunction={register("nom")}
        />
      </Form>
    </SimpleModal>
  );
};

export default ModalNouveauPoste;
