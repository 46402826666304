import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import "./AtelierError.css";

const AtelierError = ({ errorIcon, errorMessage, clearError }) => {
  return (
    <Row className="w-100 h-100" onClick={() => clearError()}>
      <Col
        xs={12}
        className="text-danger d-flex justify-content-center align-items-center"
      >
        <div className="text-center">
          <p className="atelier-error">{errorIcon}</p>
          <p className="atelier-error text-uppercase fw-bold">{errorMessage}</p>
          <p>
            <Spinner animation="border" />
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default AtelierError;
