import { jwtDecode } from "jwt-decode";

const DecodeToken = () => {
  const token = localStorage.getItem("token");
  if (!token || token === "undefined") {
    return null;
  } else {
    const decoded = jwtDecode(token);
    return decoded.uid;
  }
};

export default DecodeToken;
