import React, { useEffect, useState } from "react";
import { Button, Modal, Stack } from "react-bootstrap";
import "./DashboardModal.css";

const DashboardModal = ({
  show,
  handleClose,
  onExited,
  onSubmit,
  children,
  iconTitle,
  title,
  subTitle,
  details,
  validationButtonText,
  dataMenu,
}) => {
  const [content, setContent] = useState(null);
  const [indexMenu, setIndexMenu] = useState(null);

  const internalSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }
  };

  const handleMenu = (menuButton, index) => {
    if (menuButton) {
      setContent(menuButton.component);
      setIndexMenu(index + 1);
    }
  };

  useEffect(() => {
    setIndexMenu(1);
    setContent(dataMenu[0].component);
  }, []);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="custom-modal"
      fullscreen={true}
      backdrop="static"
      keyboard={false}
      centered
      onExited={() => onExited()}
    >
      <Modal.Body className="fullscreen-body">
        <div className="d-flex h-100">
          <div className="flex-grow-1 mt-4 ms-4 me-4 modal-body-left">
            <Stack direction="horizontal" gap={3}>
              <div>
                <div className="Montserrat-SemiBold h5 text-uppercase mb-0">
                  {title}
                </div>
                <div className="Montserrat-Regular h6">{subTitle}</div>
              </div>
              <div className="ms-auto h5">
                {dataMenu &&
                  dataMenu &&
                  dataMenu.map((element, index) => {
                    return (
                      <Button
                        className={
                          "button-menu Montserrat-Regular " +
                          (indexMenu === index + 1 && "active")
                        }
                        key={index}
                        onClick={() => handleMenu(element, index)}
                      >
                        {element.text}
                        {index + 1 < dataMenu.length && (
                          <span className="mx-3">|</span>
                        )}
                      </Button>
                    );
                  })}
              </div>
            </Stack>
            <div className="content">{content}</div>
          </div>
          {/* Partie droite */}
          <div className="modal-body-right w-25 d-none d-xl-block p-4 h-100">
            <Modal.Title className="mb-4">
              <Stack direction="horizontal" gap={2} className="mb-4">
                <div>{iconTitle}</div>
                <div>
                  <div className="Montserrat-SemiBold h5 text-uppercase mb-0">
                    {title}
                  </div>
                  <div className="Montserrat-Regular h6">{subTitle}</div>
                </div>
                <Button
                  className="ms-auto btn-close pe-3"
                  onClick={handleClose}
                ></Button>
              </Stack>
            </Modal.Title>
            <div className="details">{details}</div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DashboardModal;
