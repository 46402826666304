import React from "react";
import "./PosteTypeIcon.css";

const PosteTypeIcon = ({
  className,
  size,
  idPosteType,
  reverse,
  fillOpacity,
}) => {
  return (
    <svg
      className={`poste-icon ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}em`}
      height={`${size}em`}
      style={reverse && { transform: "scaleX(-1)" }}
      viewBox="0 0 185.20832 291.04168"
    >
      {(() => {
        switch (idPosteType) {
          case "1":
            return (
              <>
                <path
                  className={"poste-type-icon fill-50 stroke-6 " + fillOpacity}
                  d="M 47.812762,247.75896 150.2912,199.97248 168.74212,211.31898 29.361838,276.31306 Z"
                />
                <path
                  className={"poste-type-icon fill-50 stroke-6 " + fillOpacity}
                  d="M 157.87386,176.48634 V 69.285067 l 18.45092,-28.554084 2e-5,147.101857 z"
                />
                <path
                  className={"poste-type-icon fill-50 stroke-6 " + fillOpacity}
                  d="m 21.779191,112.796759 18.45093,11.34648 v 107.20126 l -18.45093,28.5541 z"
                />
                <path
                  className={"poste-type-icon fill-50 stroke-6 " + fillOpacity}
                  d="M 29.361831,89.310613 168.74211,24.316545 150.2912,52.870623 47.812756,100.65711 Z"
                />
                <g transform="translate(-57.857706,-15.564899)">
                  <path
                    className={"poste-type-icon stroke-6 " + fillOpacity}
                    d="M 68.768614,93.529053 208.14888,28.534966 226.59982,39.881444 87.219543,104.87553 Z"
                  />
                  <path
                    className={"poste-type-icon stroke-6 " + fillOpacity}
                    d="m 197.23232,73.526027 10.91658,-5.090483 13.01678,-20.144409 13.01681,8.004747 -18.49923,28.576623 z"
                  />
                  <path
                    className={"poste-type-icon stroke-6 " + fillOpacity}
                    d="M 61.185961,264.11703 V 117.01519 l 18.45093,11.34649 v 147.10183 z"
                  />
                  <path
                    className={"poste-type-icon stroke-6 " + fillOpacity}
                    d="m 197.28063,180.70476 -0.0483,-107.178733 18.49924,11.323955 V 192.05124 Z"
                  />
                  <path
                    className={"poste-type-icon stroke-6 " + fillOpacity}
                    d="m 208.1489,215.53738 -18.45093,-11.34648 -91.61016,42.71851 -5.434133,8.40971 13.016783,8.00474 z"
                  />
                  <path
                    className={"poste-type-icon stroke-6 " + fillOpacity}
                    d="m 105.67048,263.32385 -13.016803,-8.00473 -13.016786,20.14439 -5.434135,-3.34175 -5.434137,8.40971 18.450924,11.34649 z"
                  />
                </g>
              </>
            );
          case "5":
            return (
              <g transform="translate(-7.53436,-16.436947)">
                <path
                  className={"poste-type-icon stroke-6 " + fillOpacity}
                  d="M 29.361839,109.26091 157.82555,49.357298 139.42292,77.888879 47.812756,120.6074 Z"
                />
                <g transform="translate(-57.857706,-15.564899)">
                  <path
                    className={"poste-type-icon stroke-6 " + fillOpacity}
                    d="M 87.219545,124.82581 68.768616,113.47933 197.28063,53.553196 215.73156,64.899681"
                  />
                  <path
                    className={"poste-type-icon stroke-6 " + fillOpacity}
                    d="m 68.768624,280.53145 -8e-6,-167.05212 18.450929,11.34648 v 167.05214 z"
                  />
                  <path
                    className={"poste-type-icon stroke-6 " + fillOpacity}
                    d="m 197.28063,93.453778 v 9.949042 m -0.29911,117.34197 -18.45093,-11.34647 0,-56.35622 m 0,-40.89599 v -9.94905"
                  />
                  <path
                    className={"poste-type-icon stroke-6 " + fillOpacity}
                    d="m 197.28063,220.60532 -18.45093,-11.34647 -27.09599,12.63506 m -36.91021,17.21151 -9.15304,4.26813 v 19.95031 l 9.15304,-4.26815"
                  />
                </g>
                <g transform="translate(-21.286469,-34.338592)">
                  <path
                    className={"poste-type-icon stroke-6 " + fillOpacity}
                    d="m 50.648309,143.5995 18.450917,11.34649 2.1e-5,127.15153 -18.450938,28.55412 z"
                  />
                </g>

                <path
                  className={"poste-type-icon stroke-6 " + fillOpacity}
                  d="m 139.42292,87.837926 1e-5,-9.949046 18.45092,-28.554084 -0.0483,39.870866 m 0.0483,127.181258 -18.45094,-11.3465"
                />
                <path
                  className={"poste-type-icon stroke-6 " + fillOpacity}
                  d="m 66.215386,259.12796 -36.853548,17.1851 18.450924,-28.5541 9.153028,-4.26814 m 82.45713,-38.45039 18.45094,11.34649"
                />
                <g transform="translate(7.53436,16.436947)">
                  <g transform="translate(-20.955856,-1.877602)">
                    <path
                      className={"poste-type-icon stroke-6 " + fillOpacity}
                      d="M 88.838215,123.07545 70.387286,111.72897 162.04574,68.987931 180.49667,80.334416"
                    />
                    <path
                      className={"poste-type-icon stroke-6 " + fillOpacity}
                      d="m 70.387286,238.88052 0.02413,-127.13671 18.450929,11.34648 -0.02414,127.13673 z"
                    />
                    <path
                      className={"poste-type-icon stroke-6 " + fillOpacity}
                      d="m 162.04578,109.88397 0,86.2555 -18.45093,-11.34647 0,-66.30526"
                    />
                    <path
                      className={"poste-type-icon stroke-6 " + fillOpacity}
                      d="M 162.04578,196.13947 143.59485,184.793 107.28914,201.72258 v 19.95031 z"
                    />
                  </g>
                  <path
                    className={
                      "poste-type-icon fill-50 stroke-6 " + fillOpacity
                    }
                    d="M 67.882359,121.19785 159.54081,78.456812 141.13818,106.98839 86.333279,132.54434 Z"
                  />
                  <g transform="translate(15.61538,-20.651294)">
                    <path
                      className={
                        "poste-type-icon fill-50 stroke-6 " + fillOpacity
                      }
                      d="m 52.291111,141.86398 18.450917,11.34649 -0.02412,87.23612 -18.450938,28.55412 z"
                    />
                  </g>
                  <path
                    className={
                      "poste-type-icon fill-50 stroke-6 " + fillOpacity
                    }
                    d="m 141.08992,194.26187 0,-87.25098 18.45092,-28.554078 1e-4,127.151558 z"
                  />
                  <path
                    className={
                      "poste-type-icon fill-50 stroke-6 " + fillOpacity
                    }
                    d="m 93.867648,236.23224 54.756632,-25.53343 18.45094,11.34649 -91.658492,42.74104 z"
                    transform="translate(-7.5343601,-16.436947)"
                  />
                </g>
              </g>
            );
          case "7":
            return (
              <>
                <path
                  className={"poste-type-icon fill-50 " + fillOpacity}
                  d="m 47.812762,247.75896 110.061088,-51.32235 18.45094,11.3465 -146.962952,68.52995 z"
                />
                <path
                  className={"poste-type-icon fill-50 " + fillOpacity}
                  d="m 157.87385,196.43661 1e-5,-127.151543 18.45092,-28.554084 1e-5,167.052127 z"
                />
                <path
                  className={"poste-type-icon fill-50 " + fillOpacity}
                  d="m 29.361839,109.26091 18.450917,11.34649 2.1e-5,127.15153 -18.450938,28.55412 z"
                />
                <path
                  className={"poste-type-icon fill-50 " + fillOpacity}
                  d="m 29.361839,109.26091 c 0,0 18.450917,-41.826693 69.518737,-65.640009 40.501314,-18.886073 77.444204,-2.889936 77.444204,-2.889936 l -18.49923,28.576627 c 0,0 -26.18357,-15.622474 -58.944974,-0.345582 -32.761402,15.276893 -51.06782,51.64539 -51.06782,51.64539 z"
                />
                <g transform="translate(-57.857706,-15.564899)">
                  <path
                    className={"poste-type-icon"}
                    d="M 87.219545,124.82581 68.768616,113.47933 c 0,0 26.531795,-45.668457 69.518734,-65.640008 42.98693,-19.971552 77.44421,-2.889939 77.44421,-2.889939 l 18.45093,11.346485"
                  />
                  <path
                    className={"poste-type-icon"}
                    d="m 68.768624,280.53145 -8e-6,-167.05212 18.450929,11.34648 v 167.05214 z"
                  />
                  <path
                    className={"poste-type-icon"}
                    d="m 215.73161,84.849982 -1e-5,127.151528 -18.45097,-11.34647 -0.041,-121.311917"
                  />
                  <path
                    className={"poste-type-icon"}
                    d="m 215.73156,212.00151 -18.45093,-11.34647 -91.61017,42.71851 v 19.95031 z"
                  />
                </g>
              </>
            );
          case "4":
            return (
              <g transform="translate(-7.5826552,-16.414414)">
                <path
                  className={"poste-type-icon stroke-6 " + fillOpacity}
                  d="m 103.49597,241.74371 -74.134132,34.56935 18.450924,-28.5541 55.683208,-25.96554 m 61.96053,-28.89266 18.45094,11.34649 -7.58267,3.53584"
                />
                <path
                  className={"poste-type-icon stroke-6 " + fillOpacity}
                  d="m 165.4565,65.749203 18.45092,-28.55408 2e-5,167.052127 -18.45094,-11.3465"
                />
                <g transform="translate(-21.28647,-34.338591)">
                  <path
                    className={"poste-type-icon stroke-6 " + fillOpacity}
                    d="m 50.648309,143.5995 18.450917,11.34649 2.1e-5,127.15153 -18.450938,28.55412 z"
                  />
                </g>
                <path
                  className={"poste-type-icon stroke-6 " + fillOpacity}
                  d="M 29.361839,109.26091 183.90744,37.142953 165.45651,65.697052 47.812756,120.6074 Z"
                />
                <g transform="translate(-57.857706,-15.564899)">
                  <path
                    className={"poste-type-icon stroke-6 " + fillOpacity}
                    d="M 87.219545,124.82581 68.768616,113.47933 223.31421,41.413523 241.76514,52.760008"
                  />
                  <path
                    className={"poste-type-icon stroke-6 " + fillOpacity}
                    d="m 68.768624,280.53145 -8e-6,-167.05212 18.450929,11.34648 v 167.05214 z"
                  />
                  <path
                    className={"poste-type-icon stroke-6 " + fillOpacity}
                    d="m 223.31421,81.314105 -0.0483,127.174055 -18.40267,-11.36898 V 89.917917"
                  />
                  <path
                    className={"poste-type-icon stroke-6 " + fillOpacity}
                    d="m 223.31421,208.46565 -18.45093,-11.34647 -99.19282,46.25437 v 19.95031 l 55.68322,-25.96554"
                  />
                </g>
                <path
                  className={"poste-type-icon stroke-6"}
                  d="m 176.25098,269.24493 c 0,11.65766 -15.96982,27.78612 -33.66476,28.19957 -29.2604,-0.30123 -39.07488,-14.45777 -39.09025,-20.89052"
                />
                <path
                  className={"poste-type-icon stroke-6"}
                  d="m 176.25098,220.58306 c 0,11.65766 -3e-5,48.66187 -30.96427,53.977 -36.62538,-0.0557 -41.77537,-40.2352 -41.79074,-46.66795"
                />
                <path
                  className={"poste-type-icon fill-50 stroke-6"}
                  d="m 176.3572,209.90795 c -0.83374,39.8109 -66.90615,41.60701 -72.96744,11.935 0.83375,-39.8109 66.90614,-41.60702 72.96744,-11.935 z"
                />
                <path
                  className={"poste-type-icon stroke-6 " + fillOpacity}
                  d="m 103.49598,222.85592 c 0,0 -0.7365,29.37373 8.23513,30.09642 5.38563,0.43382 1.17945,-13.52213 9.3541,-4.4279 3.30332,3.67491 3.10951,12.0975 6.94442,12.20666 7.92975,0.22572 6.09246,-18.60363 6.09246,-18.60363 m 28.3893,-6.75194 c 0,0 0.10913,18.63211 4.52245,16.24042 12.15981,-6.58979 9.21714,-31.03289 9.21714,-31.03289"
                />
                <path
                  className={"poste-type-icon fill-50 stroke-6 " + fillOpacity}
                  d="m 103.49598,218.40597 c 4.33986,36.45477 76.1595,27.60956 72.86122,-8.49802 l -0.10625,59.33698 c -3.92799,35.59739 -65.26726,35.9097 -72.75498,7.30905 z"
                />
              </g>
            );
          case "8":
            return (
              <>
                <path
                  className={"poste-type-icon stroke-6"}
                  d="m 47.812762,247.75896 91.610158,-42.71853 18.45094,11.34649 -128.512022,59.92614 z"
                />
                <path
                  className={"poste-type-icon stroke-6"}
                  d="m 139.42292,205.04042 1e-5,-127.15154 18.45092,-28.554084 1e-5,167.052124 z"
                />
                <path
                  className={"poste-type-icon stroke-6"}
                  d="m 29.361839,109.26091 18.450917,11.34649 2.1e-5,127.15153 -18.450938,28.55412 z"
                />
                <path
                  className={"poste-type-icon stroke-6"}
                  d="M 29.361839,109.26091 157.82555,49.357298 139.42292,77.888879 47.812756,120.6074 Z"
                />
                <g transform="translate(-57.857706,-15.564899)">
                  <path
                    className={"poste-type-icon stroke-6"}
                    d="M 87.219545,124.82581 68.768616,113.47933 197.28063,53.553196 215.73156,64.899681"
                  />
                  <path
                    className={"poste-type-icon stroke-6"}
                    d="m 68.768624,280.53145 -8e-6,-167.05212 18.450929,11.34648 v 167.05214 z"
                  />
                  <path
                    className={"poste-type-icon stroke-6"}
                    d="M 197.28063,93.453778 V 220.60532 L 178.8297,209.25885 V 102.05759"
                  />
                  <path
                    className={"poste-type-icon stroke-6"}
                    d="m 197.28063,220.60532 -18.45093,-11.34647 -73.15924,34.1147 v 19.95031 z"
                  />
                  <path
                    className={"poste-type-icon stroke-6"}
                    d="M 197.28063,93.453778 V 220.60532 L 178.8297,209.25885 V 102.05759"
                  />
                </g>
                <g transform="translate(7.53436,16.436947)">
                  <path
                    className={"poste-type-icon fill-50 stroke-6"}
                    d="M 77.083595,126.85619 168.74211,84.115194 V 211.26673 l -91.658515,42.74105 z"
                  />
                </g>
              </>
            );
          case "2":
            return (
              <g transform="translate(-7.5826552,-16.414414)">
                <path
                  className={"poste-type-icon stroke-6"}
                  d="M 47.812762,247.75896 165.4565,192.90076 183.90744,204.24725 29.361838,276.31306 Z"
                />
                <path
                  className={"poste-type-icon stroke-6"}
                  d="m 183.90742,148.48121 2e-5,55.76604 -18.45094,-11.3465 v -35.90791 m 0.0483,-40.42108 -0.0483,-50.822557 18.45092,-28.55408 c 0,0 0.71654,66.331537 1e-5,90.693417"
                />
                <path
                  className={"poste-type-icon stroke-6 "}
                  d="m 29.361839,109.260909 18.450917,11.34649 2.1e-5,127.15153 -18.450938,28.55412 z"
                />
                <path
                  className={"poste-type-icon stroke-6 "}
                  d="M 29.361839,109.26091 183.90744,37.142953 165.45651,65.697052 47.812756,120.6074 Z"
                />
                <g transform="translate(-57.857706,-15.564899)">
                  <path
                    className={"poste-type-icon stroke-6 "}
                    d="M 87.219545,124.82581 68.768616,113.47933 223.31421,41.413523 241.76514,52.760008"
                  />
                  <path
                    className={"poste-type-icon stroke-6 "}
                    d="m 68.768624,280.53145 -8e-6,-167.05212 18.450929,11.34648 v 167.05214 z"
                  />
                  <path
                    className={"poste-type-icon stroke-6 "}
                    d="m 223.31421,81.314105 v 50.845145 m 0,39.90051 -0.0483,36.4284 -18.40267,-11.36898 v -16.45562 m 0,-39.90062 V 89.917917"
                  />
                  <path
                    className={"poste-type-icon stroke-6 "}
                    d="m 223.31421,208.46565 -18.45093,-11.34647 -99.19282,46.25437 v 19.95031 z"
                  />
                </g>
                <g>
                  <path
                    className={"poste-type-icon fill-50 stroke-6 "}
                    d="m 66.26368,182.79895 117.64375,-54.91041 1e-5,20.00251 -117.64376,54.85818 z"
                  />
                  <g>
                    <path
                      className={"poste-type-icon stroke-6 "}
                      d="m 47.812759,171.45248 18.450922,11.34647 -1e-6,19.95028 -18.450921,-11.34648 z m 0,0 117.643751,-54.85813 18.45092,11.29419 -117.643749,54.91041 z"
                    />
                  </g>
                </g>
              </g>
            );
          case "6":
            return (
              <g transform="translate(-7.5826552,-16.414414)">
                <path
                  className={"poste-type-icon stroke-6"}
                  d="M 47.812762,247.75896 165.4565,192.90076 183.90744,204.24725 29.361838,276.31306 Z"
                />
                <path
                  className={"poste-type-icon stroke-6"}
                  d="m 165.4565,65.749203 18.45092,-28.55408 2e-5,167.052127 -18.45094,-11.3465"
                />
                <g transform="translate(-21.28647,-34.338591)">
                  <path
                    className={"poste-type-icon stroke-6 "}
                    d="m 50.648309,143.5995 18.450917,11.34649 2.1e-5,127.15153 -18.450938,28.55412 z"
                  />
                </g>
                <path
                  className={"poste-type-icon stroke-6 "}
                  d="M 29.361839,109.26091 183.90744,37.142953 165.45651,65.697052 47.812756,120.6074 Z"
                />
                <g transform="translate(-57.857706,-15.564899)">
                  <path
                    className={"poste-type-icon stroke-6 "}
                    d="M 87.219545,124.82581 68.768616,113.47933 223.31421,41.413523 241.76514,52.760008"
                  />
                  <path
                    className={"poste-type-icon stroke-6 "}
                    d="M 87.219545,124.82581 68.768616,113.47933 223.31421,41.413523 241.76514,52.760008"
                  />
                  <path
                    className={"poste-type-icon stroke-6 "}
                    d="m 68.768624,280.53145 -8e-6,-167.05212 18.450929,11.34648 v 167.05214 z"
                  />
                  <path
                    className={"poste-type-icon stroke-6 "}
                    d="m 223.31421,81.314105 -0.0483,127.174055 -18.40267,-11.36898 V 89.917917"
                  />
                  <path
                    className={"poste-type-icon stroke-6 "}
                    d="m 223.31421,208.46565 -18.45093,-11.34647 -99.19282,46.25437 v 19.95031 z"
                  />
                </g>
                <path
                  className={"poste-type-icon stroke-6 "}
                  d="m 135.15741,138.33105 c -0.27024,13.1561 -4.32698,26.43747 -23.64375,38.29202 -18.930276,5.63708 -35.833372,6.20633 -45.249979,-6.16203 2.669114,-13.65398 7.134885,-29.3893 22.749611,-37.94168 17.315358,-9.48383 34.248318,-9.93543 46.144118,5.81169 z"
                />
                <ellipse
                  className={"poste-type-icon fill-50 stroke-6 "}
                  cx="91.905441"
                  cy="160.09636"
                  rx="17.033449"
                  ry="24.745396"
                  transform="rotate(-3.898333)"
                />
              </g>
            );
          case "3":
            return (
              <g transform="translate(-7.53436,-16.436947)">
                <path
                  className={"poste-type-icon stroke-6"}
                  d="m 66.297701,239.13927 73.125219,-34.09884 18.45094,11.34649 -128.512022,59.92614 18.450924,-28.5541 12.099672,-5.64219"
                />
                <path
                  className={"poste-type-icon stroke-6"}
                  d="m 139.42292,205.04042 1e-5,-127.15154 18.45092,-28.554084 1e-5,167.052124 z"
                />
                <g transform="translate(-21.28647,-34.338591)">
                  <path
                    className={"poste-type-icon stroke-6"}
                    d="m 50.648309,143.5995 18.450917,11.34649 2.1e-5,127.15153 -18.450938,28.55412 z"
                  />
                </g>
                <path
                  className={"poste-type-icon stroke-6"}
                  d="M 29.361839,109.26091 157.82555,49.357298 139.42292,77.888879 47.812756,120.6074 Z"
                />
                <g transform="translate(-57.857706,-15.564899)">
                  <path
                    className={"poste-type-icon stroke-6"}
                    d="M 87.219545,124.82581 68.768616,113.47933 197.28063,53.553196 215.73156,64.899681"
                  />
                  <path
                    className={"poste-type-icon stroke-6"}
                    d="m 68.768624,280.53145 -8e-6,-167.05212 18.450929,11.34648 v 167.05214 z"
                  />
                  <path
                    className={"poste-type-icon stroke-6"}
                    d="M 197.28063,93.453778 V 220.60532 L 178.8297,209.25885 V 102.05759"
                  />
                  <path
                    className={"poste-type-icon stroke-6"}
                    d="m 124.15541,254.70417 73.12522,-34.09885 -18.45093,-11.34647 -73.15924,34.1147 v 19.95031 l 12.09968,-5.64219"
                  />
                </g>
                <path
                  className={"poste-type-icon fill-50 stroke-6"}
                  d="m 59.994765,124.8759 6.302936,-2.93912 v 28.09501 l -6.302936,2.93909 z"
                />
                <path
                  className={"poste-type-icon fill-50 stroke-6"}
                  d="m 59.912437,233.97206 6.302936,-2.93912 v 28.09501 l -6.302936,2.93909 z"
                />
                <path
                  className={"poste-type-icon fill-50 stroke-6"}
                  d="m 166.01316,75.43871 6.30293,-2.93912 v 28.09501 l -6.30293,2.93909 z"
                />
                <path
                  className={"poste-type-icon fill-50 stroke-6"}
                  d="m 165.71581,184.63514 6.30294,-2.93912 v 28.09501 l -6.30294,2.93909 z"
                />
              </g>
            );
          default:
            return <></>;
        }
      })()}
    </svg>
  );
};

export default PosteTypeIcon;
