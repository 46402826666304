import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef, useState } from "react";
import { Form, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import DashboardCard from "../../../../components/CustomCard/DashboardCard/DashboardCard";
import CustomCode128 from "../../../../components/CustomCode128/CustomCode128";
import DashboardModal from "../../../../components/CustomModal/DashboardModal/DashboardModal";
import BasicInput from "../../../../components/inputs/BasicInput/BasicInput";
import config from "../../../../config/config";
import useFetch from "../../../../services/useFetch";
import TimelineProduit from "./TimelineProduit/TimelineProduit";

const schema = yup
  .object({
    nom: yup.string().required("Nom du poste obligatoire."),

    type: yup.number().required("Type obligatoire").integer(),
  })
  .required();

const ModalProduit = ({ show, handleClose, idProduit }) => {
  const apiUrlProduit = config.apiUrl + "/product-form/" + idProduit;
  const isComponentMounted = useRef(true);
  const [localDataProduit, setLocalDataProduit] = useState(null);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    loading: loadingProduit,
    data: dataProduit,
    triggerFetch: triggerFetchProduit,
  } = useFetch(apiUrlProduit, isComponentMounted, []);

  const menu = [
    {
      text: "Activité",
      component: <TimelineProduit idProduit={idProduit}></TimelineProduit>,
    },
  ];

  useEffect(() => {
    if (show) {
      triggerFetchProduit(apiUrlProduit, {
        method: "GET",
      });
    }
  }, [show]);

  useEffect(() => {
    if (dataProduit) {
      setLocalDataProduit(dataProduit);
    }
  }, [dataProduit]);

  return (
    <>
      {localDataProduit && (
        <DashboardModal
          dataMenu={menu}
          fullscreen={true}
          show={show}
          handleClose={handleClose}
          title={
            parseInt(localDataProduit.commande.value) +
            "." +
            localDataProduit.extension.value
          }
          subTitle={"position " + localDataProduit.position.value}
          details={
            <>
              <DashboardCard>
                <Stack direction="horizontal" gap={3}>
                  <div>
                    <DashboardCard
                      theme="light"
                      className="d-inline-block"
                    ></DashboardCard>
                  </div>
                  <div className="w-50">
                    <div className="Montserrat-SemiBold h5 mb-0">
                      Enregistrement
                    </div>
                    <div className="Montserrat-SemiBold h5 mb-0"></div>
                  </div>
                </Stack>
                <CustomCode128
                  value={localDataProduit.code_barre.value}
                  widthLine={1}
                  height={10}
                ></CustomCode128>
                <Stack direction="horizontal" gap={3}></Stack>
              </DashboardCard>
              <BasicInput
                labelText="Identifiant"
                controlId="id"
                control={control}
                name="id"
                placeholder={localDataProduit.id.value}
                disabled={localDataProduit.id.disabled}
              />
              <BasicInput
                labelText="Numéro de commande"
                controlId="commande"
                control={control}
                name="commande"
                placeholder={parseInt(localDataProduit.commande.value)}
                disabled={localDataProduit.commande.disabled}
              />
              <BasicInput
                labelText="Extension de la commande"
                controlId="extension"
                control={control}
                name="extension"
                placeholder={localDataProduit.extension.value}
                disabled={localDataProduit.extension.value}
              />
              <BasicInput
                labelText="Position dans la commande"
                controlId="position"
                control={control}
                name="position"
                placeholder={parseInt(localDataProduit.position.value)}
                disabled={localDataProduit.position.disabled}
              />
              <BasicInput
                labelText="Repère de la quantité"
                controlId="quantite"
                control={control}
                name="quantite"
                placeholder={parseInt(localDataProduit.repere_quantite.value)}
                disabled={localDataProduit.repere_quantite.disabled}
              />
              <BasicInput
                labelText="Elément"
                controlId="element"
                control={control}
                name="element"
                placeholder={
                  parseInt(localDataProduit.repere_element.value) +
                  (parseInt(localDataProduit.repere_element.value) > 0
                    ? " (ouvrant)"
                    : " (dormant)")
                }
                disabled={localDataProduit.repere_element.disabled}
              />
              <BasicInput
                labelText="Barre scannée"
                controlId="barre"
                control={control}
                name="barre"
                placeholder={parseInt(localDataProduit.repere_quantite.value)}
                disabled={localDataProduit.repere_quantite.disabled}
              />
              <BasicInput
                labelText="Enregistré par"
                controlId="operateur"
                control={control}
                name="operateur"
                placeholder={
                  localDataProduit.operateur.nom.value +
                  " " +
                  localDataProduit.operateur.prenom.value
                }
                disabled={true}
              />
              <BasicInput
                labelText="Enregistré le"
                controlId="creation"
                control={control}
                name="creation"
                placeholder={localDataProduit.creation.value}
                disabled={localDataProduit.creation.disabled}
              />
            </>
          }
          validationButtonText={"Enregistrer le poste"}
          onExited={() => {
            reset({}, { errors: true });
            setLocalDataProduit(null);
          }}
        >
          <Form noValidate></Form>
        </DashboardModal>
      )}
    </>
  );
};

export default ModalProduit;
