import React from 'react';

const saveToken = (localVariableName, localVariableValue) => {

    localStorage.clear();
    console.log('Token supprimé');
    localStorage.setItem(localVariableName, localVariableValue);
    console.log('Token enregistré:', localVariableValue);

};

export default saveToken;