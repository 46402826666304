import { AnimatePresence, motion } from "framer-motion";
import React, { useContext } from "react";
import { Container } from "react-bootstrap";
import { FooterContext, UserContext } from "../../../App";
import AlignBox from "../../../components/AlignBox/AlignBox";
import ResizableTable from "../../../components/ResizableTable/ResizableTable";
import ClassificationBadge from "../../../components/badgeComponents/ClassificationBadge/ClassificationBadge";
import StatutBadge from "../../../components/badgeComponents/StatutBadge/statutBadge";
import UrgentBadge from "../../../components/badgeComponents/UrgentBadge/UrgentBadge";
import IconSecteur from "../../../components/iconComponents/IconSecteur/IconSecteur";
import IconTypeSav from "../../../components/iconComponents/IconTypeSav/IconTypeSav";
import IconUserType from "../../../components/iconComponents/IconUserType/IconUserType";
import config from "../../../config/config";
import FormatDateDDMMYYYY from "../../../utilitaires/FormatDate/FormatDateDDMMYYYY";
import { FreeSecondNavBarContext } from "../ServiceApresVente";

function TableauDeBordServiceApresVente(props) {
  const searchBoxRefContext = useContext(FreeSecondNavBarContext);
  const footerRefContext = useContext(FooterContext);
  const userData = useContext(UserContext);
  const userId = userData.id;
  const apiUrl = config.apiUrl;

  const tableConfig = [
    {
      nom: "Statut",
      key: "statut_libelle",
      render: (row) => <StatutBadge statut={row.statut_libelle} />,
      width: 80,
      center: true,
    },
    {
      nom: "Type",
      key: "type",
      render: (row) => <IconTypeSav>{row.type}</IconTypeSav>,
      width: 60,
      center: true,
    },
    {
      nom: "Numéro",
      key: "id",
      width: 80,
      center: true,
    },
    {
      nom: "Création",
      key: "creation",
      render: (row) => (
        <>
          <IconUserType>{row.user}</IconUserType>
          <FormatDateDDMMYYYY>{row.creation}</FormatDateDDMMYYYY>
        </>
      ),
      width: 120,
      center: true,
    },
    {
      nom: "Commande",
      key: "commande",
      width: 95,
      center: true,
    },
    {
      nom: "Ext.",
      key: "extension",
      width: 60,
      center: true,
    },
    {
      nom: "Client",
      key: "client.nom",
      render: (row) => (
        <>
          {row.client.nom}
          <ClassificationBadge
            classification={row.client.classification.libelle}
          />
        </>
      ),
      width: 350,
    },
    {
      nom: "Référence",
      key: "reference",
    },
    {
      nom: "Technicien(ne)",
      key: "technicien",
      width: 200,
      center: true,
    },
    {
      nom: "Secteur",
      key: "secteur",
      render: (row) => <IconSecteur>{row.secteur}</IconSecteur>,
      width: 75,
      center: true,
    },
    {
      nom: "",
      key: "urgent",
      render: (row) => <UrgentBadge>{row.urgent}</UrgentBadge>,
      width: 60,
      center: true,
    },
  ];

  const cardConfig = {
    header: (row) => "n°" + row.id,
    headerClass: (row) =>
      StatutBadge({ statut: row.statut_libelle, type: "bg" }),
    title: (row) => (
      <AlignBox className="justify-content-end mt-1 text-secondary">
        <IconTypeSav size={1}>{row.type}</IconTypeSav>
      </AlignBox>
    ),
    icon: (row) => (
      <StatutBadge statut={row.statut_libelle} type="icon" iconSize={25} />
    ),
    text: (row) => (
      <>
        client: {row.client.nom}
        <ClassificationBadge
          classification={row.client.classification.libelle}
        />
        <br />
        création: <IconUserType>{row.user}</IconUserType>
        <FormatDateDDMMYYYY>{row.creation}</FormatDateDDMMYYYY>
        <br />
        commande: {row.commande}.{row.extension}
        <br />
        référence: {row.reference}
        <br />
        technicien(ne): {row.technicien}
      </>
    ),
    footer: (row) => (
      <>
        <IconSecteur>{row.secteur}</IconSecteur>
        <UrgentBadge className="ms-3">{row.urgent}</UrgentBadge>
      </>
    ),
  };

  return (
    <Container fluid className="p-0">
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 25 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.2 }}
        >
          <ResizableTable
            urlFetch={apiUrl + "/sav-table/" + userId}
            limit={50}
            indexOrder="sav_id"
            viewModeInit="table"
            indexSort={2}
            sortInit="DESC"
            heightAdjust={37}
            searchInputRef={searchBoxRefContext}
            switchViewRef={searchBoxRefContext}
            paginationRef={footerRefContext && footerRefContext.middle}
            filterResultRef={footerRefContext && footerRefContext.start}
            totalRef={footerRefContext && footerRefContext.end}
            tableConfig={tableConfig}
            cardConfig={cardConfig}
          />
        </motion.div>
      </AnimatePresence>
    </Container>
  );
}

export default TableauDeBordServiceApresVente;
