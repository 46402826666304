import React from 'react';
import { PiThumbsDownDuotone } from "react-icons/pi";
import { motion } from 'framer-motion';

const RTNoResult = (props) => {

  return (
    <motion.div 
        className='text-center mt-5 text-secondary'
        initial={{y: 25, opacity: 0}}
        animate={{y: 0, opacity:1}}
    >
        <h1>
            <PiThumbsDownDuotone />
        </h1>
        <h5 >
            {props.message}
        </h5>
    </motion.div>
  );
};

export default RTNoResult;