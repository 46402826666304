import React from 'react';

const NouveauClient = () => {

  return (
    <>
      Ici c'est la page des nouveau client
    </>
  );
};

export default NouveauClient;