import React from "react";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";

function SortArrow (props) {
    switch(props.sort){
        case 'DESC':
            return(
                <MdArrowDropDown size={'1.5em'}/>
            );
        break;
        case 'ASC':
            return(
                <MdArrowDropUp size={'1.5em'}/>
            );
        break;
        default:
            return(
                <></>
            );
    }
}

export default SortArrow;