import './PaginationButton.css';
import React from 'react';

const PaginationButton = ({ children, onClick, className, disabled}) => {

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    }

  return (

    <button onClick={handleClick} className={'button-page ' + className} disabled={disabled}>
      {children}
    </button>

  );
};

export default PaginationButton;