import React from "react";
import { Button, Modal, Stack } from "react-bootstrap";
import "./SimpleModal.css";

const DashboardModal = ({
  show,
  handleClose,
  onExited,
  onSubmit,
  children,
  iconTitle,
  title,
  subTitle,
  details,
  validationButtonText,
  fullscreen,
}) => {
  const internalSubmit = () => {
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="custom-modal"
      backdrop="static"
      keyboard={false}
      centered
      onExited={() => onExited()}
    >
      <Modal.Header closeButton>
        <Modal.Title className="Montserrat-SemiBold">
          <Stack direction="horizontal" gap={2}>
            <div>{iconTitle}</div>
            <div>{title}</div>
          </Stack>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="m-4">{children}</Modal.Body>
      <Modal.Footer>
        <Button
          size="lg"
          variant="light-secondary"
          className="Montserrat-Regular"
          onClick={handleClose}
        >
          Annuler
        </Button>
        <Button
          size="lg"
          variant="perform"
          className="Montserrat-Regular"
          onClick={internalSubmit}
        >
          Enregistrer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DashboardModal;
