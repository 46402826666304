import React from "react";
import "./PosteIcon.css";

const PosteIcon = ({ className, size, tache, reverse, fillOpacity }) => {
  return (
    <>
      {(() => {
        switch (tache) {
          case "scan_product":
            return (
              <svg
                className={`stockage-icon ${className}`}
                xmlns={"http://www.w3.org/2000/svg"}
                width={`${size}`}
                height={`${size}`}
                style={reverse && { transform: "scaleX(-1)" }}
                viewBox="0 0 255 265"
              >
                <path
                  className={"poste-icon fill-25 stroke-5 " + fillOpacity}
                  d="m 43.186333,191.45993 c -0.366169,0.17075 54.697347,-25.5058 79.716457,-37.1724 l 10.70685,10.76222 -103.707427,48.35957 z M 122.90279,49.267426 134.19067,27.236442 133.60964,165.04975 122.86503,154.30514 Z m -93.000577,164.141894 13.28412,-21.94939 0,-105.02011 -13.28412,-10.572872 z M 29.737741,76.152018 134.0262,27.521512 122.90279,49.267426 43.186333,86.43982 Z"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 125.29564,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={"poste-icon stroke-5 no-round " + fillOpacity}
                  d="m 134.29148,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 143.28732,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 147.78524,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 156.65751,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 165.65335,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 170.15127,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 174.64919,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 183.64503,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 188.14295,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 102.80604,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 107.30396,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  d="m 116.2998,210.52432 c 0,8.33726 0,24.36221 0,28.28921"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 93.201605,210.23955 v -8.92537 l 8.92538,-1e-5"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 102.126995,247.73891 h -8.92537 l -2e-5,-8.92538"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 197.35046,238.81353 v 8.92537 l -8.92538,1e-5"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 188.42509,201.59894 h 8.92537 l 1e-5,8.92538"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="M 19.178162,65.142898 30.21332,76.178056 M 19.178162,65.142898 122.90279,16.77531 134.19067,27.236438 30.05479,75.795798 Z"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 19.178162,202.68527 1e-6,-137.542358 10.72405,10.724036 0,137.542372 z"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 113.92046,145.36058 0,-91.904624 8.98233,-4.18853 -0.0378,105.037714 z m -70.734127,32.98386 70.734127,-32.98386 8.94457,8.94456 -79.678697,37.15479 z"
                />
              </svg>
            );
          case "scan_operateur":
            return (
              <svg
                className={`stockage-icon ${className}`}
                xmlns={"http://www.w3.org/2000/svg"}
                width={`${size}`}
                height={`${size}`}
                style={reverse && { transform: "scaleX(-1)" }}
                viewBox="0 0 255 265"
              >
                <path
                  className={"poste-icon fill-25 stroke-5 " + fillOpacity}
                  d="m 146.13506,159.20905 -58.116424,27.10014 -58.11642,27.10013 c -10e-7,-28.76886 -0.291989,-62.251 58.11642,-86.27413 47.787784,-19.65491 58.116424,3.305 58.116424,32.07386 z"
                />
                <ellipse
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  cx="109.20477"
                  cy="56.292614"
                  rx="28.36734"
                  ry="31.625977"
                  transform="matrix(0.95480261,0.29724062,-0.32049019,0.94725184,0,0)"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 130.97048,141.62942 3.25296,-1.51689 v 3.67488 m 0,7.34976 v 3.67488 l -3.25296,1.51689 m -6.50594,3.03376 -3.25297,1.51689 v -3.67488 m 0,-7.34976 v -3.67488 l 3.25297,-1.51688"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 149.00342,210.23954 v -8.92537 l 8.92538,-1e-5"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 157.92881,247.7389 h -8.92537 l -2e-5,-8.92538"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 194.5,238.50424 v 9 l -8.92538,1e-5"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="155.35808"
                  y="207.72438"
                  ry="2.0436025e-16"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="155.35808"
                  y="230.49046"
                  ry="2.0436025e-16"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="176.94482"
                  y="230.62279"
                  ry="2.0436025e-16"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 155.29373,224.65236 h 11.66132"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 172.82752,224.65236 h 4.98031"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.05765,213.73814 v -6.01376"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.05765,213.73814 h 3.65096"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.00796,219.19525 h 7.04883"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 185.27496,219.19525 h 2.72069"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 171.69639,240.66093 v -8.82609"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 183.026,224.65236 h 5.02042"
                />
                <path
                  className={"poste-icon stroke-4 " + fillOpacity}
                  d="m 182.14573,213.73814 h 5.89089"
                />
                <path
                  className={"poste-icon stroke-4 " + fillOpacity}
                  d="m 178.93687,207.76771 8.98377,-0.0433"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 185.42509,201.59894 h 8.92537 l 1e-5,8.92538"
                />
              </svg>
            );
          case "scan_emplacement":
            return (
              <svg
                className={`stockage-icon ${className}`}
                xmlns={"http://www.w3.org/2000/svg"}
                width={`${size}`}
                height={`${size}`}
                style={reverse && { transform: "scaleX(-1)" }}
                viewBox="0 0 255 265"
              >
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 119.5158,91.823308 5.54952,-2.587782 56.3618,53.516564 -107.827564,50.28082 -54.421394,-54.42139 26.47179,-12.344 34.426653,-16.05341"
                />
                <path
                  className={"poste-icon stroke-5 fill-25 " + fillOpacity}
                  d="m 96.363624,57.688467 c -13.656853,10e-7 -24.728206,11.071352 -24.728206,24.728205 10e-7,13.656852 20.522076,45.073238 24.728206,51.733788 4.259166,-6.74127 24.727686,-38.076936 24.727686,-51.733788 0,-13.656855 -11.07083,-24.728207 -24.727686,-24.728205 z m 0,14.468367 a 9.0993452,9.0899792 0 0 1 9.099186,9.089884 9.0993452,9.0899792 0 0 1 -9.099186,9.089884 9.0993452,9.0899792 0 0 1 -9.099186,-9.089884 9.0993452,9.0899792 0 0 1 9.099186,-9.089884 z"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 149.00342,210.23954 v -8.92537 l 8.92538,-1e-5"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 157.92881,247.7389 h -8.92537 l -2e-5,-8.92538"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 194.5,238.50424 v 9 l -8.92538,1e-5"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="155.35808"
                  y="207.72438"
                  ry="2.0436025e-16"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="155.35808"
                  y="230.49046"
                  ry="2.0436025e-16"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="176.94482"
                  y="230.62279"
                  ry="2.0436025e-16"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 155.29373,224.65236 h 11.66132"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 172.82752,224.65236 h 4.98031"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.05765,213.73814 v -6.01376"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.05765,213.73814 h 3.65096"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.00796,219.19525 h 7.04883"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 185.27496,219.19525 h 2.72069"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 171.69639,240.66093 v -8.82609"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 183.026,224.65236 h 5.02042"
                />
                <path
                  className={"poste-icon stroke-4 " + fillOpacity}
                  d="m 182.14573,213.73814 h 5.89089"
                />
                <path
                  className={"poste-icon stroke-4 " + fillOpacity}
                  d="m 178.93687,207.76771 8.98377,-0.0433"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 185.42509,201.59894 h 8.92537 l 1e-5,8.92538"
                />
              </svg>
            );
          case "scan_responsable":
            return (
              <svg
                className={`stockage-icon ${className}`}
                xmlns={"http://www.w3.org/2000/svg"}
                width={`${size}`}
                height={`${size}`}
                style={reverse && { transform: "scaleX(-1)" }}
                viewBox="0 0 255 265"
              >
                <path
                  className={"poste-icon fill-25 stroke-5 " + fillOpacity}
                  d="m 158.61318,73.301283 -11.45434,-7.860249 -12.96873,4.979715 3.93596,-13.322674 -8.74355,-10.795174 13.88689,-0.373617 7.56492,-11.6515 4.64662,13.091767 13.41892,3.594151 -11.01513,8.464773 z"
                />
                <path
                  className={"poste-icon fill-25 stroke-5 " + fillOpacity}
                  d="m 146.13506,159.20905 -58.116424,27.10014 -58.11642,27.10013 c -10e-7,-28.76886 -0.291989,-62.251 58.11642,-86.27413 47.787784,-19.65491 58.116424,3.305 58.116424,32.07386 z"
                />
                <ellipse
                  className={
                    "poste-icon fill-25 stroke-5 no-round " + fillOpacity
                  }
                  cx="109.20477"
                  cy="56.292614"
                  rx="28.36734"
                  ry="31.625977"
                  transform="matrix(0.95480261,0.29724062,-0.32049019,0.94725184,0,0)"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 130.97048,141.62942 3.25296,-1.51689 v 3.67488 m 0,7.34976 v 3.67488 l -3.25296,1.51689 m -6.50594,3.03376 -3.25297,1.51689 v -3.67488 m 0,-7.34976 v -3.67488 l 3.25297,-1.51688"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 149.00342,210.23954 v -8.92537 l 8.92538,-1e-5"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 157.92881,247.7389 h -8.92537 l -2e-5,-8.92538"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 194.5,238.50424 v 9 l -8.92538,1e-5"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="155.35808"
                  y="207.72438"
                  ry="2.0436025e-16"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="155.35808"
                  y="230.49046"
                  ry="2.0436025e-16"
                />
                <rect
                  className={
                    "poste-icon fill-25 stroke-4 no-round " + fillOpacity
                  }
                  width="11.089869"
                  height="11.089869"
                  x="176.94482"
                  y="230.62279"
                  ry="2.0436025e-16"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 155.29373,224.65236 h 11.66132"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 172.82752,224.65236 h 4.98031"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.05765,213.73814 v -6.01376"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.05765,213.73814 h 3.65096"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 173.00796,219.19525 h 7.04883"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 185.27496,219.19525 h 2.72069"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 171.69639,240.66093 v -8.82609"
                />
                <path
                  className={"poste-icon fill-25 stroke-4 " + fillOpacity}
                  d="m 183.026,224.65236 h 5.02042"
                />
                <path
                  className={"poste-icon stroke-4 " + fillOpacity}
                  d="m 182.14573,213.73814 h 5.89089"
                />
                <path
                  className={"poste-icon stroke-4 " + fillOpacity}
                  d="m 178.93687,207.76771 8.98377,-0.0433"
                />
                <path
                  className={"poste-icon stroke-5 " + fillOpacity}
                  d="m 185.42509,201.59894 h 8.92537 l 1e-5,8.92538"
                />
              </svg>
            );
          default:
            return <></>;
        }
      })()}
    </>
  );
};

export default PosteIcon;
