import React from 'react';

const TableauDeBordAdministration = () => {

  return (

    <>
      Ici c'est le tableau de bord del'administration
    </>

  );
};

export default TableauDeBordAdministration;