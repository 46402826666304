import { yupResolver } from "@hookform/resolvers/yup";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { PiQrCodeDuotone } from "react-icons/pi";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import AnimatedLogo from "../../components/AnimatedLogo/AnimatedLogo";
import BasicInput from "../../components/inputs/BasicInput/BasicInput";
import useFetchLoginAtelier from "../../services/useFetchLoginAtelier";
import "./AuthentificationAtelier.css";

const schema = yup
  .object({
    // qrcode: yup.string()
    // .matches(/^[0-9]{8}[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}[1234]{1}[0-9A-Z]{3}$/, 'Le code-barre ne correspond pas aux critères requis')
    // .length(20, 'Le code-barre doit contenir exactement 20 caractères')
    // .required('Le code-barre est requis'),
  })
  .required();

const AuthentificationAtelier = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const inputRef = useRef(null);

  const { fetchLoginAtelier } = useFetchLoginAtelier();

  const {
    register,
    formState: { isValid },
    handleSubmit,
    setFocus,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = (data) => {
    if (isValid) {
      fetchLoginAtelier(data.qrcode, navigate);
    }
    inputRef.current.value = "";
  };

  const handleKeyDown = (event) => {
    if (event && event.key === "Enter") {
      event.preventDefault();
      handleSubmit(handleFormSubmit)();
      // Insère ici le reste de ta logique liée à la touche Entrée
    }
  };

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        // Clic à l'extérieur du champ, rétablir le focus
        inputRef.current.focus();
      }
    };

    // Ajouter un écouteur d'événements pour gérer les clics à l'extérieur
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Nettoyer l'écouteur d'événements lors du démontage du composant
      document.removeEventListener("click", handleClickOutside);
    };
  }, [inputRef]);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        className="authentification-box text-center"
        transition={{ ease: "easeInOut", duration: 0.3 }}
        layout
      >
        <motion.div
          key={location.pathname}
          initial={{ y: -8, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -8, opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 0.5, delay: 0.2 }}
        >
          <AnimatedLogo size={1.8} />
          <motion.div
            animate={{
              scale: [1, 1.04, 1],
            }}
            transition={{
              duration: 1,
              repeat: Infinity,
            }}
          >
            <PiQrCodeDuotone
              size={50}
              className="text-textural "
            ></PiQrCodeDuotone>
            <p className="Montserrat-SemiBold pb-2 text-uppercase text-textural h5 mt-3 px-lg-2 ">
              scanner le QRCode du poste <br />
              pour commercer
            </p>
            <Form noValidate>
              <BasicInput
                inputRef={inputRef}
                labelText="qrcode"
                controlId="qrcode"
                control={control}
                name="qrcode"
                setFocus={setFocus}
                placeholder="QRcode"
                registerFunction={register}
                onKeyDown={handleKeyDown}
                hidden
              />
            </Form>
          </motion.div>
          <div className="Montserrat-SemiBold pb-2 text-uppercase text-textural h5 mt-3 px-lg-2 ">
            <div className="mt-5 Roboto-Regular text-black">version 2.0.0</div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};
export default AuthentificationAtelier;
