import React from "react";
import "./UserProfermIcon.css";

const UserProfermIcon = ({
  className,
  size,
  idGroupe,
  reverse,
  fillOpacity,
}) => {
  return (
    <svg
      className={`custom-icon ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}em`}
      height={`${size}em`}
      style={reverse && { transform: "scaleX(-1)" }}
      viewBox="0 0 180 190"
    >
      <path
        className="user-icon"
        d="M 134.22698,98.248743 V 157.82674 L 4.9590391,179.35446 V 28.660394 C 27.934079,32.317866 50.909118,35.975339 73.884158,39.632811"
      />
      <circle
        className={"user-icon fill-25 " + fillOpacity}
        cx="69.592972"
        cy="104.00755"
        r="28.145622"
      />
      <path
        className={"user-icon fill-25 " + fillOpacity}
        d="m 28.110302,176.33458 c -0.564047,-2.31875 -0.860259,-4.71957 -0.860259,-7.1786 -3e-6,-19.67221 18.957591,-35.61969 42.34297,-35.61969 2.923172,0 5.777161,0.24918 8.533587,0.72367 2.756425,0.47448 5.415288,1.17428 7.94821,2.0755 2.532923,0.90123 4.939899,2.00389 7.19256,3.28412 2.252661,1.28022 4.351006,2.738 6.266653,4.34948 1.915637,1.61147 3.648587,3.37664 5.170447,5.27161 1.52187,1.89499 2.83266,3.91978 3.904,6.05052 1.07133,2.13074 1.90321,4.36743 2.46726,6.68618"
      />

      {(() => {
        switch (idGroupe) {
          case 0:
            return (
              <path
                className={"user-icon fill-25 " + fillOpacity}
                d="m 110.2649,21.637354 3.54875,19.088966 18.91534,-4.380968 3.54876,19.088975 18.91533,-4.380975 -20.02905,30.591123 -44.928175,-29.415998 z"
              />
            );
          case 2:
            return (
              <path
                className={"user-icon fill-25 stroke-5 " + fillOpacity}
                d="M 108.60847,43.834863 94.128683,34.771797 77.047389,34.952758 86.110455,20.472971 85.929494,3.391677 l 14.479786,9.063066 17.08129,-0.180961 -9.06306,14.479787 z"
                transform="matrix(1.3485402,0,0,1.3485402,-7.5149403,16.226237)"
              />
            );
          case 3:
            return (
              <>
                <path
                  className={"user-icon fill-25 " + fillOpacity}
                  d="m 112.10205,28.571559 h 33.23778 c 4.68234,0 8.45188,3.769537 8.45188,8.451877 v 20.852831 c 0,4.68234 -3.76954,8.451876 -8.45188,8.451876 h -33.23778 c -2.34117,0 0,0 -12.572458,1.998174 4.120588,-10.45005 4.120588,-8.10888 4.120588,-10.45005 V 37.023436 c 0,-4.68234 3.76953,-8.451877 8.45187,-8.451877 z"
                />
              </>
            );
          case 6:
            return (
              <>
                <path
                  className="user-icon"
                  d="M 114.43091,18.943706 126.61969,34.521844 120.03723,48.627882 104.762,51.623931 92.573216,36.045793"
                />
                <path
                  className="user-icon"
                  d="m 120.03723,48.627882 26.24055,33.537293"
                />
              </>
            );
          case 7:
            return (
              <>
                <rect
                  className={"user-icon fill-25 " + fillOpacity}
                  width="44.353283"
                  height="59.765625"
                  x="103.55729"
                  y="14.105647"
                  rx="0"
                  ry="8.4518766"
                />
                <path
                  className="user-icon"
                  d="m 114.77405,41.676606 h 21.91977"
                />
                <path
                  className="user-icon"
                  d="m 114.56505,27.805502 h 21.91977"
                />
                <path
                  className="user-icon"
                  d="m 114.56505,60.171413 h 21.91977"
                />
              </>
            );
          case 8:
            return (
              <>
                <path
                  className={"user-icon fill-25 " + fillOpacity}
                  d="M 132.23112,43.834862 A 15.226629,15.226629 0 0 1 117.00449,59.06149 15.226629,15.226629 0 0 1 101.77788,43.834862 15.226629,15.226629 0 0 1 117.00449,28.608234 15.226629,15.226629 0 0 1 132.23112,43.834862 Z"
                />
                <path
                  className="user-icon"
                  d="m 113.19882,69.449311 h 7.61134"
                />
                <path
                  className="user-icon"
                  d="m 113.19882,79.929621 h 7.61134"
                />
                <path
                  className="user-icon"
                  d="m 137.89291,62.328404 2.65437,2.450715"
                />
                <path
                  className="user-icon"
                  d="m 137.83455,25.253498 2.57155,-2.537471"
                />
                <path
                  className="user-icon"
                  d="m 93.367558,22.614695 2.654392,2.450717"
                />
                <path
                  className="user-icon"
                  d="m 144.9455,43.85937 3.61239,-0.049"
                />
                <path
                  className="user-icon"
                  d="m 116.99293,15.756701 -0.0489,-3.612389"
                />
              </>
            );
          case 9:
            return (
              <>
                <rect
                  className={"user-icon fill-25 " + fillOpacity}
                  width="65.632446"
                  height="23.288935"
                  x="92.131897"
                  y="-58.038715"
                  ry="7.2963228"
                  rx="0"
                  transform="rotate(45)"
                />
                <path
                  className="user-icon"
                  d="m 116.50414,36.873692 -7.21316,7.213153"
                />
                <path
                  className="user-icon"
                  d="m 140.07539,60.444943 -7.21315,7.213153"
                />
                <path
                  className="user-icon"
                  d="m 128.28977,48.659318 -7.21315,7.213152"
                />
              </>
            );
          case 10:
            return (
              <>
                <circle
                  className={"user-icon fill-25 " + fillOpacity}
                  cx="102.20061"
                  cy="34.631958"
                  r="11.382533"
                />
                <circle
                  className={"user-icon fill-25 " + fillOpacity}
                  cx="137.70694"
                  cy="52.311207"
                  r="11.382533"
                />
                <path
                  className={"user-icon fill-25 " + fillOpacity}
                  d="m 85.457009,63.209381 c 0,-9.496476 7.496366,-17.194891 16.743601,-17.194889 9.24723,4e-6 16.74359,7.698417 16.74359,17.194889"
                />
                <path
                  className={"user-icon fill-25 " + fillOpacity}
                  d="m 120.96333,80.888631 c 0,-9.496476 7.49636,-17.194891 16.7436,-17.194889 9.24723,4e-6 16.74359,7.698417 16.74359,17.194889"
                />
              </>
            );
          case 11:
            return (
              <>
                <rect
                  className={"user-icon fill-25 " + fillOpacity}
                  width="36.998035"
                  height="31.058054"
                  x="102.79581"
                  y="-41.07394"
                  ry="13.734042"
                  rx="0"
                  transform="matrix(0.80348188,0.59532921,-0.50984892,0.86026396,0,0)"
                />
                <path
                  className="user-icon"
                  d="m 103.53607,25.862919 14.94932,-3.387536"
                />
                <path
                  className="user-icon"
                  d="m 132.36374,47.222399 14.94932,-3.387536"
                />
                <path
                  className="user-icon"
                  d="m 117.42838,74.607057 13.74333,-3.373374"
                />
                <path
                  className="user-icon"
                  d="M 118.48539,22.475381 147.31306,43.834862 131.6682,70.330709"
                />
              </>
            );
          case 12:
            return (
              <>
                <rect
                  className={"user-icon fill-25 " + fillOpacity}
                  width="52.35466"
                  height="35.973946"
                  x="95.528091"
                  y="-50.529102"
                  rx="0"
                  ry="23.289135"
                  transform="matrix(0.80711984,0.59038764,-0.59072093,0.80687594,0,0)"
                />
                <ellipse
                  className="user-icon"
                  cx="106.19317"
                  cy="-9.6411867"
                  rx="3.2445161"
                  ry="3.4516153"
                  transform="rotate(36.557759)"
                />
                <ellipse
                  className="user-icon"
                  cx="122.15356"
                  cy="-10.357855"
                  rx="3.2445161"
                  ry="3.4516153"
                  transform="rotate(36.557759)"
                />
                <ellipse
                  className="user-icon"
                  cx="155.77789"
                  cy="-11.054932"
                  rx="3.2445161"
                  ry="3.4516153"
                  transform="rotate(36.557759)"
                />
                <rect
                  className="user-icon fill-25"
                  width="18.361612"
                  height="18.72159"
                  x="148.17247"
                  y="-32.951057"
                  ry="9.1808062"
                  rx="0"
                  transform="rotate(35.713988)"
                />
              </>
            );
          case 13:
            return (
              <>
                <circle
                  className={"user-icon fill-25 " + fillOpacity}
                  cx="86.76667"
                  cy="99.999481"
                  r="18.567608"
                />
                <path
                  className="user-icon"
                  d="m 112.27757,39.412577 -3.6691,-8.302933"
                />
                <path
                  className="user-icon"
                  d="m 130.98424,81.744517 -3.6691,-8.30293"
                />
                <path
                  className="user-icon"
                  d="m 145.06874,45.22176 -8.30293,3.669096"
                />
                <path
                  className="user-icon"
                  d="m 102.67347,63.956416 -8.302921,3.669098"
                />
                <path
                  className="user-icon"
                  d="M 102.41063,49.676254 93.945105,46.399646"
                />
                <path
                  className="user-icon"
                  d="M 145.57144,66.381845 137.10593,63.10523"
                />
                <path
                  className="user-icon"
                  d="m 129.7052,30.597111 -3.27661,8.465493"
                />
                <path
                  className="user-icon"
                  d="m 112.97462,73.822492 -3.27661,8.465496"
                />
              </>
            );
          case 14:
            return (
              <path
                className="user-icon"
                d="m 96.80557,50.313976 20.8693,29.362725 36.77565,-50.189772"
              />
            );
          case 15:
            return (
              <>
                <path
                  className="user-icon"
                  d="m 143.93516,69.57399 c -4.34576,4.345763 -10.34937,7.033661 -16.98077,7.033661 -13.26284,0 -24.01449,-10.751628 -24.01449,-24.014456 0,-13.262822 10.75165,-24.014466 24.01449,-24.014466 6.6314,0 12.63501,2.687911 16.98077,7.033674"
                />
                <path
                  className="user-icon"
                  d="m 96.020352,47.137943 27.187558,0.08031"
                />
                <path
                  className="user-icon"
                  d="m 96.013182,59.709151 23.009098,0.09462"
                />
              </>
            );
          default:
            <></>;
        }
      })()}
    </svg>
  );
};

export default UserProfermIcon;
