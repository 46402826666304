import React, { useEffect, useRef } from "react";
import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react';
import LogoQRCodeProferm from '../../assets/logo/logo-qrcode-proferm.png'

const QRCodeLogo = ({size, value }) => {

    try {
        const imageSettings = {
            src: LogoQRCodeProferm, // Composant SVG du logo
            excavate: true
        };
        
        return (
            <div>
                <QRCodeCanvas
                    size={size} 
                    value={value} 
                    imageSettings={imageSettings}
                />
            </div>
        );
    } catch (error) {
        console.error("Erreur lors du rendu du QR code :", error);
    }

}

export default QRCodeLogo;
