import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { UserContext } from "../../../../App";
import AlignBox from "../../../../components/AlignBox/AlignBox";
import UserProfermIcon from "../../../../components/CustomIcons/UserProfermIcon/UserProfermIcon";
import DashboardModal from "../../../../components/CustomModal/DashboardModal/DashboardModal";
import Loader from "../../../../components/Loader/Loader";
import BasicInput from "../../../../components/inputs/BasicInput/BasicInput";
import FetchSelect from "../../../../components/inputs/FetchSelect/FetchSelect";
import config from "../../../../config/config";
import useFetch from "../../../../services/useFetch";

const schema = yup
  .object({
    prenom: yup.string().required("Prenom de l'utilisateur obligatoire."),

    nom: yup.string().required("Nom de l'utilisateur obligatoire."),

    groupe: yup.number().required("Groupe obligatoire").integer(),
  })
  .required();

const ModalUtilisateurProferm = ({
  show,
  handleClose,
  idUtilisateurProferm,
}) => {
  const userData = useContext(UserContext);
  const userId = userData.id;
  const apiUrlCreatePoste = config.apiUrl + "/poste-create/" + userId;
  const apiUrlGroupe = config.apiUrl + "/groupe-liste/" + userId;
  const apiUrlUtilisateurProferm =
    config.apiUrl + "/user-proferm/" + userId + "/" + idUtilisateurProferm;
  const isComponentMounted = useRef(true);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const menu = [
    {
      text: "Activité",
      component: "",
    },
  ];

  const {
    loading: loadingCreatePoste,
    data: dataCreatePoste,
    triggerFetch: triggerFetchCreatePoste,
  } = useFetch(apiUrlCreatePoste, isComponentMounted, []);

  const {
    loading: loadingUtilisateurProferm,
    data: dataUtilisateurProferm,
    triggerFetch: triggerFetchUtilisateurProferm,
  } = useFetch(apiUrlUtilisateurProferm, isComponentMounted, []);

  useEffect(() => {
    if (show) {
      triggerFetchUtilisateurProferm(apiUrlUtilisateurProferm, {
        method: "GET",
      });
    }
  }, [show]);

  useEffect(() => {
    if (dataUtilisateurProferm) {
      setValue("nom", dataUtilisateurProferm.nom.value);
      setValue("prenom", dataUtilisateurProferm.prenom.value);
      setValue("groupe", dataUtilisateurProferm.groupe.id.value);
    }
  }, [dataUtilisateurProferm]);

  const onSuccess = () => {
    handleClose();
  };

  const onSubmit = (data) => {
    console.log("Données soumises:", data);
    triggerFetchCreatePoste(
      apiUrlCreatePoste,
      {
        method: "POST",
        body: JSON.stringify(data),
      },
      onSuccess
    );
  };

  return (
    <>
      <DashboardModal
        dataMenu={menu}
        fullscreen={true}
        show={show}
        handleClose={handleClose}
        iconTitle={
          <UserProfermIcon
            size={1.7}
            idGroupe={
              dataUtilisateurProferm && dataUtilisateurProferm.groupe.id.value
            }
          />
        }
        title={
          (dataUtilisateurProferm && dataUtilisateurProferm.prenom.value) +
          " " +
          (dataUtilisateurProferm && dataUtilisateurProferm.nom.value)
        }
        validationButtonText={"Enregistrer le poste"}
        onSubmit={handleSubmit(onSubmit)}
        onExited={() => reset()}
      >
        {dataUtilisateurProferm ? (
          <Form noValidate>
            <Row>
              <Col xxl={3} className="bg-white p-4 rounded">
                <BasicInput
                  labelText="Identifiant"
                  controlId="id"
                  control={control}
                  name="id"
                  placeholder={dataUtilisateurProferm.id.value}
                  disabled={!dataUtilisateurProferm.id.field}
                />
                <BasicInput
                  labelText="Nom"
                  controlId="nom"
                  control={control}
                  name="nom"
                  placeholder="Entrez le nom de l'utilisateur"
                  errorMessage={errors.nom?.message}
                  registerFunction={register("nom")}
                />
                <BasicInput
                  labelText="Prénom"
                  controlId="prenom"
                  control={control}
                  name="prenom"
                  placeholder="Entrez le prénom de l'utilisateur"
                  errorMessage={errors.prenom?.message}
                  registerFunction={register("prenom")}
                />
                <FetchSelect
                  urlOptions={apiUrlGroupe}
                  labelText="Groupe"
                  onSelectChange={(selectedValue) =>
                    setValue("groupe", selectedValue)
                  }
                  placeholder="Selectionnez le groupe"
                  defaultIdValue={dataUtilisateurProferm.groupe.id.value}
                  control={control}
                  name="groupe"
                  option={{
                    value: "id",
                    text: (row) => (
                      <>
                        <AlignBox>
                          <UserProfermIcon
                            idGroupe={row.id}
                            size={2}
                            className="me-3"
                          />
                          {row.libelle}
                        </AlignBox>
                      </>
                    ),
                  }}
                  setValue={setValue}
                  errorMessage={errors.groupe?.message}
                  registerFunction={register("groupe")}
                />
                <BasicInput
                  labelText="Dernière connexion"
                  controlId="last-connexion"
                  control={control}
                  name="last-connexion"
                  placeholder={dataUtilisateurProferm.last_connexion.date.value}
                  disabled={!dataUtilisateurProferm.last_connexion.date.field}
                />
                <BasicInput
                  labelText="Adresse IP lors de la dernière connexion"
                  controlId="last-connexion-ip"
                  control={control}
                  name="last-connexion-ip"
                  placeholder={dataUtilisateurProferm.last_connexion.ip.value}
                  disabled={!dataUtilisateurProferm.last_connexion.ip.field}
                />
                <BasicInput
                  labelText="Date de création"
                  controlId="creation"
                  control={control}
                  name="creation"
                  placeholder={dataUtilisateurProferm.creation.date.value}
                  disabled={!dataUtilisateurProferm.creation.date.field}
                />
                <BasicInput
                  labelText="Adresse IP de création"
                  controlId="ip-creation"
                  control={control}
                  name="ip-creation"
                  placeholder={dataUtilisateurProferm.creation.ip.value}
                  disabled={!dataUtilisateurProferm.creation.ip.field}
                />
              </Col>
            </Row>
          </Form>
        ) : (
          <Loader>
            Chargement des informations de l'utilisateur en cours...
          </Loader>
        )}
      </DashboardModal>
    </>
  );
};

export default ModalUtilisateurProferm;
