import React from "react";
import './UrgentBadge.css';
import { Badge } from "react-bootstrap";

function UrgentBadge (props) {
    return(
        props.children === '1' ?
        <Badge bg="danger" className={"urgent-badge " + props.className}>URGENT</Badge> :
        ''
    )
}

export default UrgentBadge;