import React from "react";
import "./IconBarre.css";

const IconBarre = ({ className, size, barre, reverse, fillOpacity }) => {
  return (
    <svg
      className={`stockage-icon ${className}`}
      xmlns={"http://www.w3.org/2000/svg"}
      width={`${size}`}
      height={`${size}`}
      style={reverse && { transform: "scaleX(-1)" }}
      viewBox="0 0 132.29166 132.29167"
    >
      <path
        className={
          "barre-icon stroke-5 fill-50 " +
          (barre !== "1" && barre !== "G" && "opacity-25")
        }
        d="M 5.2916666,13.229165 24.826357,32.763855 V 99.527808 L 5.2916628,119.0625 Z"
      />
      <path
        className={
          "barre-icon stroke-5 fill-50 " +
          (barre !== "2" && barre !== "H" && "opacity-25")
        }
        d="M 119.0625,5.291667 99.527809,24.826357 H 32.763856 L 13.229164,5.291663 Z"
      />
      <path
        className={
          "barre-icon stroke-5 fill-50 " +
          (barre !== "3" && barre !== "D" && "opacity-25")
        }
        d="M 126.99999,119.0625 107.4653,99.527806 V 32.763853 l 19.53469,-19.534689 z"
      />
      <path
        className={
          "barre-icon fill-50 stroke-5 " +
          (barre !== "4" && barre !== "B" && "opacity-25")
        }
        d="m 13.229164,127 19.53469,-19.53469 H 99.52781 L 119.0625,127 Z"
      />
    </svg>
  );
};

export default IconBarre;
