import { yupResolver } from "@hookform/resolvers/yup";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { PiHandTapLight } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import AlignBox from "../../../components/AlignBox/AlignBox";
import AnimatedLogo from "../../../components/AnimatedLogo/AnimatedLogo";
import InputLoginFloatingLabel from "../../../components/inputs/InputLoginFloatingLabel/InputLoginFloatingLabel";
import useFetchLogin from "../../../services/useFetchLogin";
import "./Login.css";

const schema = yup
  .object({
    login: yup
      .string()
      .email("Veuillez entrer une adresse email valide.")
      .required("Veuillez saisir votre adresse email de connexion"),

    password: yup
      .string()
      .required("Veuillez saisir votre mot de passe")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{}|;:'<>,.?/èéêëàáâäçùúûü])[A-Za-z\d!@#$%^&*()_+\-=\[\]{}|;:'<>,.?/èéêëàáâäçùúûü]{8,}$/,
        "Mot de passe incorrect"
      ),
  })
  .required();

const Login = () => {
  const navigate = useNavigate();
  const [loginButton, setLoginButton] = useState(false);
  const { fetchLogin, loading } = useFetchLogin();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    fetchLogin(data.login, data.password, navigate);
  };

  return (
    <>
      <motion.div
        className="login-block"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
      >
        <AnimatedLogo size={1.8} />
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <InputLoginFloatingLabel
            controlId="loginInput"
            label="Adresse e-mail"
            type="email"
            placeholder="entreprise@exemple.com"
            autoComplete="username"
            autoFocus
            name="login"
            errors={errors.login}
            registerFunction={register("login")}
          />
          <p className="text-danger mt-1 ms-1">{errors.login?.message}</p>
          <InputLoginFloatingLabel
            controlId="passwordInput"
            label="Mot de passe"
            type="password"
            placeholder=""
            autoComplete="current-password"
            name="password"
            setFocus={setFocus}
            errors={errors.password}
            registerFunction={register("password")}
          />
          <p className="text-danger ms-1">{errors.password?.message}</p>
          <div className="d-flex align-items-center">
            <Link
              className="ms-auto fw-bold text-aluminium mb-4"
              to="/auth/utilisateur/forgot"
            >
              Mot de passe oublié ?
            </Link>
          </div>
          <Button
            disabled={loginButton}
            type="submit"
            variant="perform"
            className="mt-1 mb-5 px-4 Montserrat-Regular text-uppercase"
            size="lg"
          >
            S'identifier
          </Button>
          <div className="Montserrat-SemiBold py-2 text-uppercase text-aluminium h6 mt-3 px-lg-2 ">
            Nouveau ici ?
            <Button variant="aluminium" className="ms-2" size="sm">
              <AlignBox>
                <Link
                  className="Montserrat-SemiBold text-white text-decoration-none"
                  to="/auth/utilisateur/register"
                >
                  <PiHandTapLight size={22} /> Créez votre compte
                </Link>
              </AlignBox>
            </Button>
            <div className="mt-5 Roboto-Regular text-black">version 2.0.1</div>
          </div>
        </Form>
      </motion.div>
    </>
  );
};

export default Login;
