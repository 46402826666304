import React from 'react';

const StatistiquesProduction = () => {

  return (

    <>
      {/* Content goes here */}
    </>

  );
};

export default StatistiquesProduction;