import React from "react";
import './ClassificationBadge.css';
import { Badge } from "react-bootstrap";

function ClassificationBadge (props) {
    switch(props.classification){
        case 'EXPERT OR':
            return (
                <div className={"classification-badge ms-1 text-black bg-gold d-inline " + props.className}>OR</div>
            );
        case 'EXPERT ARGENT':
            return (
                <div className={"classification-badge ms-1 text-black bg-silver d-inline " + props.className}>ARGENT</div>
            );
        case 'HISTORIQUE':
            return (
                <div bg="old" className={"classification-badge ms-1 bg-old d-inline " + props.className}>HIST</div>
            );
        case 'NOUVEAU':
            return (
                <div bg="hybride" className={"classification-badge ms-1 bg-hybride d-inline " + props.className}>NEW</div>
            );
        default:
            return (
                <></>
            );
    }
}

export default ClassificationBadge;