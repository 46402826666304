import React from "react";
import "./PfIconPolygon.css";

const PfIconPolygon = ({ className, size}) => {
    return (
        <svg
            className={`custom-icon ${className}`}
            xmlns="http://www.w3.org/2000/svg"
            width={`${size}em`}
            height={`${size}em`}
            viewBox="0 0 60 70"
        >
            <polygon 
                points="0,0 60,10 60,60 0,70"
            />
        </svg>
    );
}

PfIconPolygon.defaultProps = {
    size: 1,
}

export default PfIconPolygon;