import React, { useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import EyePassword from "../../EyePassword/EyePassword";
import "./InputLoginFloatingLabel.css";

const InputLoginFloatingLabel = (props) => {
  const [passwordShow, setPasswordShow] = useState(true);

  const handleClickShowPassword = () => {
    props.setFocus("password");
    setPasswordShow(!passwordShow);
  };

  if (props.type !== "password") {
    return (
      <FloatingLabel controlId={props.controlId} label={props.label}>
        <Form.Control
          type={props.type}
          placeholder={props.placeholder}
          autoFocus={props.autoFocus}
          autoComplete={props.autoComplete}
          name={props.name}
          className={
            "input-login " + props.className + (props.errors ? " error" : "")
          }
          style={{ height: 60 + "px" }}
          {...props.registerFunction}
        />
        {props.name === "password" ? (
          <EyePassword
            passwordVisible={passwordShow}
            handleClick={handleClickShowPassword}
          />
        ) : null}
      </FloatingLabel>
    );
  } else {
    return (
      <div>
        <FloatingLabel
          controlId={props.controlId}
          label={props.label}
          style={{ height: 60 + "px" }}
        >
          <Form.Control
            type={passwordShow ? "password" : "text"}
            placeholder={props.placeholder}
            autoFocus={props.autoFocus}
            autoComplete={props.autoComplete}
            name={props.name}
            className={
              "input-login position-absolute pe-5 " +
              props.className +
              (props.errors ? " error" : "")
            }
            {...props.registerFunction}
          />
          <EyePassword
            passwordVisible={passwordShow}
            handleClick={handleClickShowPassword}
          />
        </FloatingLabel>
      </div>
    );
  }
};

export default InputLoginFloatingLabel;
