import "./StockageTypeIcon.css";
import React from 'react';

const StockageTypeIcon = ({className, size, idStockageType, reverse, fillOpacity}) => {

    return (
        <>
        {(() => {
            switch(idStockageType){
                case '1' :
                    return (
                        <svg
                            className={`stockage-icon ${className}`}
                            xmlns={"http://www.w3.org/2000/svg"}
                            width={`${size}em`}
                            height={`${size}em`}
                            style={ reverse && {transform: 'scaleX(-1)'} }
                            viewBox="25 45 255 265"
                        >
                            <path 
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 201.22391,178.8466 6.80523,-3.17333 0,57.84523 5.94718,5.94718 6.02738,-2.81061 1e-5,-47.97004 20.64184,20.64185 1e-5,8.838 5.94718,5.94718 6.02737,-2.81061 -35.6301,16.75892 -35.63011,16.75892 -35.6301,16.75891 -35.63011,16.75892 -45.818015,-45.6333 6.02738,-2.81061 0.06893,-8.48361 20.685231,-9.6096 5.947156,5.64217 6.692968,-3.12098 v 57.69231 l 6.39635,6.32362 5.58207,-2.62557 -0.004,-75.38956 10.96359,-5.11234 5.94717,5.64216 3.84353,-1.79227 v 57.70091 l 5.94718,5.94718 5.55501,-2.915 0.47238,-74.73229 14.25369,-6.64661 5.94718,5.64216 3.4027,-1.5867 -1e-5,57.46002 5.94718,5.94718 6.02738,-2.81061 1e-5,-74.5958 11.29197,-5.26554 z"                        
                            />
                            <path 
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 58.334495,236.75664 51.316025,51.21263 v 8.25957 L 58.265919,244.84392 Z"                        
                            />
                            <path 
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 252.62011,221.30145 -0.20638,8.35582 -142.76321,66.57157 v -8.25957 l 35.7424,-16.66695 35.7424,-16.66696 35.74239,-16.66695 z"                        
                            />
                            <path 
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 64.281675,131.38244 6.128909,6.12891 -0.101529,102.38186 -6.02738,2.81061 z"                        
                            />
                            <path 
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 58.334495,116.42257 51.316025,51.31604 0,120.23066 -5.94718,-5.94718 0,-111.21798 -39.421665,-39.42167 v 111.32138 l -5.94718,-5.94718 z"                        
                            />
                            <path 
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="M 64.281675,113.61196 115.6779,164.928 109.65052,167.73861 58.334495,116.42258 Z"                        
                            />
                            <path 
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 109.65052,167.73861 6.02738,-2.81061 0,120.23066 -6.02738,2.81061 z"                        
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 100.07236,167.17313 -0.0229,33.26829 -0.0254,25.59546 -3.01369,1.4053 v -27.83034 -21.07762 m 0,-32.22649 V 116.1208 l 6.12891,6.12891 v 30.18693"                    
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 91.063214,140.36058 0,-39.19964 51.316016,51.31603 v 120.23066 l -5.94718,-5.94718 V 155.54247 l -39.42166,-39.42167 -3e-5,30.18693 m 0,17.8034 3e-5,35.50071 v 27.83034 L 91.063214,221.495 v -63.33101"                            
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 97.01039,98.350324 51.39622,51.316036 -6.02738,2.81061 -51.316016,-51.31602 z"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 142.37923,152.47697 6.02738,-2.81061 v 120.23066 l -6.02738,2.81061 z"                            
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 195.27673,91.365908 -10e-6,-38.800538 51.31602,51.31603 v 120.23066 l -5.94718,-5.94718 V 106.9469 l -39.42166,-39.42167 1e-5,29.13836 m 0,20.06772 -1e-5,34.28495 v 27.83035 l -5.94718,-5.94718 v -30.08351 l 10e-6,-31.93383"                            
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 201.2239,49.75476 51.39622,51.31603 -6.02738,2.81061 -51.31602,-51.31602 z"                            
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 246.59274,103.8814 6.02738,-2.81061 v 120.23066 l -6.02738,2.81061 z"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 207.35281,121.82129 -0.0254,25.59547 -0.0762,28.61924 -6.02738,2.81061 v -27.83034 l 8e-5,-34.28496 m 0,-20.06772 0,-29.138358 6.1289,6.12891 v 29.491498"                            
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 135.63904,154.74946 -0.0114,28.86072 -0.0254,25.59546 -3.01369,1.4053 V 182.7806 161.70298 m 2e-5,-27.7851 -2e-5,-34.628325 6.12891,6.128915 v 34.6283"   
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 126.64135,127.97071 -1e-5,-43.641017 51.31602,51.316037 v 120.23066 l -5.94718,-5.94718 V 138.71123 l -39.42166,-39.421675 -3e-5,30.186935 m 3e-5,22.22245 c 8e-4,118.10216 -10e-6,19.24809 0,31.08166 v 27.83034 l -5.94718,-5.94718 1e-5,-58.91199"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 132.58852,81.519077 51.39622,51.316043 -6.02738,2.81061 -51.31601,-51.316027 z"                            
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 177.95736,135.64573 6.02738,-2.81061 v 120.23066 l -6.02738,2.81061 z"                            
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 171.658,138.33876 -0.0114,28.86071 -0.0254,25.59546 -3.01369,1.40531 v -27.83036 -21.0776 m 2e-5,-27.7851 -2e-5,-34.62834 6.12891,6.12892 v 34.62831"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 162.66031,111.56 -1e-5,-43.64102 51.31602,51.31605 v 120.23065 l -5.94718,-5.94718 V 122.30052 l -39.42166,-39.42168 -3e-5,30.18694 m 3e-5,22.22245 c 8e-4,118.10216 -1e-5,19.24809 0,31.08166 v 27.83034 l -5.94718,-5.94718 1e-5,-58.91199"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 168.60748,65.10836 51.39622,51.31606 -6.02738,2.81061 -51.31601,-51.31604 z"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity} 
                                d="m 213.97632,119.23503 6.02738,-2.81061 v 120.23065 l -6.02738,2.81061 z"                            
                            />
                        </svg>
                    );
                case '2':
                    return (
                        <svg
                            className={`stockage-icon ${className}`}
                            xmlns={"http://www.w3.org/2000/svg"}
                            width={`${size}em`}
                            height={`${size}em`}
                            style={ reverse && {transform: 'scaleX(-1)'} }
                            viewBox="25 45 255 265"
                        >
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 201.92699,179.36499 5.57821,-3.17846 47.36387,42.86609 -144.76938,69.2845 -45.818015,-45.6333 6.02738,-2.81061 10e-7,-8.45147 125.670754,-58.02393 z"
                            />
                            <path  
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 58.334495,236.75664 51.316025,51.21263 v 8.25957 L 58.334495,245.01622 Z"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 254.86907,219.05262 -0.20638,8.35582 -145.01217,68.8204 v -8.25957 z"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="M 108.12533,294.90076 96.045798,298.06829 95.592364,282.3678 Z"
                            />
                            <ellipse
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                ry="10.18831"
                                rx="7.1322584"
                                cy="-312.92953"
                                cx="-6.0476766"
                                transform="rotate(163.03102)" 
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 106.81143,296.15719 c 0.88489,4.15981 -0.184,8.05609 -1.96035,8.90674 l -3.9156,1.87508"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 69.270832,256.75438 -9.000606,6.25856 -1.950555,-17.24471 z"
                            />
                            <ellipse
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                ry="10.18831"
                                rx="7.1322584"
                                cy="-268.95914"
                                cx="17.939289"
                                transform="rotate(163.03102)" 
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 71.035862,261.10184 c 0.884887,4.1598 -0.184002,8.05609 -1.960347,8.90674 l -3.915604,1.87507"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 246.82061,233.11068 c 0.0105,1.27118 -0.1686,2.47351 -0.51679,3.54358 -0.3482,1.07007 -0.86545,2.00789 -1.53113,2.75003 -0.66567,0.74215 -1.47977,1.28863 -2.42166,1.57604 -0.94188,0.2874 -1.92239,0.28853 -2.889,0.0445 -0.96661,-0.24407 -1.91932,-0.73335 -2.80562,-1.42672 -0.44314,-0.34669 -0.86969,-0.74441 -1.27306,-1.18802"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 249.39168,232.87928 c -0.004,0.96354 -0.10969,1.86714 -0.29902,2.66732 -0.18933,0.80019 -0.46182,1.49697 -0.798,2.04687 -0.33618,0.54989 -0.73606,0.95291 -1.18014,1.16557 l -3.9156,1.87508"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 201.47782,57.981251 6.02738,-2.810611 v 121.01589 l -6.02738,2.81061 z"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 201.47782,49.673734 6.02738,5.496906 -6.02738,2.810611 -5.94718,-5.496904 z"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 64.281675,122.36976 6.02738,-3.26089 0,120.78434 -6.02738,2.81061 z"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 58.334495,116.42257 5.94718,5.49691 0,120.78434 -5.94718,-5.94718 z"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 64.281675,113.61196 6.02738,5.49691 -6.02738,2.81061 -5.94718,-5.4969 z"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="M 195.53064,82.62575 V 52.484347 l 5.94718,5.496904 v 30.253972 m 0,11.073111 0,49.434836 v 30.25397 l -5.94718,-5.94718 v -30.1414 l 1e-5,-40.82701"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 201.47783,89.095225 -1e-5,10.213109 -131.168764,61.165006 0,-10.52501 z"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 195.53064,82.836155 5.94719,5.947179 -131.168774,61.164996 0,-8.72038 z"
                            />
                        </svg>
                    );
                case '3':
                    return (
                        <svg
                            className={`stockage-icon ${className}`}
                            xmlns={"http://www.w3.org/2000/svg"}
                            width={`${size}em`}
                            height={`${size}em`}
                            style={ reverse && {transform: 'scaleX(-1)'} }
                            viewBox="30 0 215 265"
                        >
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="M 180.37215,48.080487 213.18299,80.891333 245.99384,113.70218 103.02425,180.37 37.402555,114.7483 Z"
                            />
                            <path  
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 37.402555,114.7483 65.621695,65.6217 0,12.14481 -65.621696,-65.62169 z"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 245.99384,113.70218 -0.20638,12.24106 -71.38161,33.28578 -71.3816,33.28579 V 180.37 Z"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="M 53.807976,131.15372 196.77757,64.485908"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="M 70.213403,147.55915 213.183,80.89134"
                            />
                            <path  
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="M 86.618824,163.96457 229.58841,97.296761"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 103.02425,192.51481 18.83239,-8.78169 0,15.46927 -18.83239,8.78168 z"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 165.19604,163.52364 18.83238,-8.78169 v 15.46927 l -18.83238,8.78168 z"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 227.16145,134.62867 18.83238,-8.78169 v 15.46927 l -18.83238,8.78168 z"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 89.000617,178.49118 14.023633,14.02363 v 15.46926 L 89.000617,193.96043 Z"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 37.357383,126.84794 14.023633,14.02363 v 15.46926 L 37.357383,142.31719 Z"
                            />
                            <path  
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 51.513844,141.00472 10.416975,10.41666 -10.416975,5.05259 z"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="M 165.19604,163.52364 V 178.9929 L 154.71763,168.40979 Z"
                            />
                            <path
                                className={'stockage-type-icon fill-25 stroke-5 '+fillOpacity}
                                d="m 227.16144,134.62868 v 15.46926 l -10.47841,-10.58311 z"
                            />
                        </svg>
                    );
                default:
                    return (
                        <></>
                    )
              }
            })()}
        </>
    );
};

export default StockageTypeIcon;