import { createCanvas, loadImage } from "canvas";
import qrcode from "qrcode";
import LogoQRCodeProferm from "../../assets/logo/logo-qrcode-proferm.png";

// Fonction pour générer un QR code en base64 (format PNG)
const QRCodeBase64 = async (text) => {
  try {
    // Génère le QR code en format URL base64
    const base64Image = await qrcode.toDataURL(text);

    const canvas = createCanvas(300, 300);
    const context = canvas.getContext("2d");

    context.drawImage(
      await loadImage(base64Image),
      0,
      0,
      canvas.width,
      canvas.height
    );

    const logo = new Image();
    logo.src = LogoQRCodeProferm; // La balise img avec la base64 du logo
    context.drawImage(
      logo,
      canvas.width / 2 - 15,
      canvas.height / 2 - 15,
      30,
      30
    ); // Ajuste la position et la taille du logo

    const finalBase64Image = canvas.toDataURL("image/png");

    return finalBase64Image;
  } catch (error) {
    console.error("Erreur lors de la génération du QR code en base64 :", error);
    return null;
  }
};

export default QRCodeBase64;
