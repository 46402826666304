import React from "react";
import './AlignBox.css';

function AlignBox (props) {

    return (
        <div className={"AlignBox "+props.className}>
            {props.children}
        </div>
    );
}

export default AlignBox;