import './Footer.css'
import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Col, Nav, Navbar, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const Footer = React.forwardRef(({ setRef }, ref) => {
    const location = useLocation();
    const startRef = useRef();
    const middleRef = useRef();
    const endRef = useRef();
    
    useEffect(() => {
        const footerRefs = {
            'start': startRef.current,
            'middle': middleRef.current,
            'end': endRef.current
        }

        if(setRef){
            setRef(footerRefs);
        }

    },[location.pathname, startRef, middleRef, endRef])

    return (
        <motion.div
            key=        { location.pathname }
            initial=    {{ opacity: 0, y: 25 }}
            animate=    {{ opacity: 1, y : 0 }}
            exit=       {{ opacity: 0, y: 25 }}
            transition= {{ duration: 0.2 }}
        >
            <Navbar expand="sm" className="footer" data-bs-theme="light" fixed='bottom' ref={ref}>
                <Navbar.Collapse id="basic-navbar-nav" className="Roboto-Regular m-0">
                    <Row className='w-100 m-0'>
                        <Col md="4" className='d-flex justify-content-start'>
                            <Nav ref={startRef}>

                            </Nav>
                        </Col>
                        <Col md="4" className='d-flex justify-content-center'>
                            <Nav ref={middleRef}>

                            </Nav>
                        </Col>
                        <Col md="4" className='d-flex justify-content-end'>
                            <Nav ref={endRef}>

                            </Nav>
                        </Col>
                    </Row>
                </Navbar.Collapse>
            </Navbar>
        </motion.div>
    );
});

export default Footer;