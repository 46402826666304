import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef, useState } from "react";
import { Form, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import AlignBox from "../../../../components/AlignBox/AlignBox";
import DashboardCard from "../../../../components/CustomCard/DashboardCard/DashboardCard";
import OperateurGroupeIcon from "../../../../components/CustomIcons/OperateurGroupeIcon/OperateurGroupeIcon";
import PfIconPolygon from "../../../../components/CustomIcons/PfIconPolygon/PfIconPolygon";
import DashboardModal from "../../../../components/CustomModal/DashboardModal/DashboardModal";
import QRCodePDF from "../../../../components/PDF/QRCodePDF/QRCodePDF";
import QRCodeLogo from "../../../../components/QRCodeLogo/QRCodeLogo";
import BasicInput from "../../../../components/inputs/BasicInput/BasicInput";
import FetchSelect from "../../../../components/inputs/FetchSelect/FetchSelect";
import config from "../../../../config/config";
import useFetch from "../../../../services/useFetch";
import TimelineOperateur from "./TimelineOperateur/TimelineOperateur";

const schema = yup
  .object({
    nom: yup.string().required("Nom de l'opérateur obligatoire."),

    prenom: yup
      .number()
      .required("Prénom de l'opérateur obligatoire.")
      .integer(),
  })
  .required();

const ModalOperateur = ({ show, handleClose, idOperateur }) => {
  const apiUrlOperateur = config.apiUrl + "/operateur-form/" + idOperateur;
  const apiUrlOperateurGroupe = config.apiUrl + "/operateur-groupes";
  const isComponentMounted = useRef(true);
  const [localDataOperateur, setLocalDataOperateur] = useState(null);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    loading: loadingOperateur,
    data: dataOperateur,
    triggerFetch: triggerFetchOperateur,
  } = useFetch(apiUrlOperateur, isComponentMounted, []);

  const menu = [
    {
      text: "Activité",
      component: <TimelineOperateur idOperateur={idOperateur} />,
    },
  ];

  useEffect(() => {
    if (show) {
      triggerFetchOperateur(apiUrlOperateur, {
        method: "GET",
      });
    }
  }, [show]);

  useEffect(() => {
    if (dataOperateur) {
      setLocalDataOperateur(dataOperateur);
      setValue("nom", dataOperateur.nom.value);
      setValue("prenom", dataOperateur.prenom.value);
    }
  }, [dataOperateur]);

  return (
    <>
      {localDataOperateur && (
        <DashboardModal
          dataMenu={menu}
          fullscreen={true}
          show={show}
          handleClose={handleClose}
          iconTitle={
            <OperateurGroupeIcon
              idOperateurGroupe={localDataOperateur.groupe.id.value}
              size={2.2}
            />
          }
          title={
            <>
              {localDataOperateur.nom.value} {localDataOperateur.prenom.value}
            </>
          }
          subTitle={localDataOperateur.groupe.libelle.value}
          details={
            <>
              <DashboardCard>
                <Stack direction="horizontal" gap={3}>
                  <div>
                    <DashboardCard theme="light" className="d-inline-block">
                      <QRCodeLogo
                        size={100}
                        viewBox={`0 0 100 100`}
                        value={localDataOperateur.qrcode}
                      >
                        <PfIconPolygon size={50}></PfIconPolygon>
                      </QRCodeLogo>
                    </DashboardCard>
                  </div>
                  <div className="w-50">
                    <div className="Montserrat-SemiBold h5 mb-0">
                      Identification
                    </div>
                    <div className="Montserrat-Regular h6 text-uppercase">
                      QR Code
                    </div>
                    <div className="w-100">{localDataOperateur.qrcode}</div>
                  </div>
                </Stack>
                <Stack direction="horizontal" gap={3}>
                  <div className="ms-auto">
                    <QRCodePDF
                      qrCodeData={localDataOperateur.qrcode}
                      title={
                        localDataOperateur.nom.value +
                        " " +
                        localDataOperateur.prenom.value
                      }
                      text="Imprimer"
                      variant="textural"
                      buttonSize="sm"
                    ></QRCodePDF>
                  </div>
                </Stack>
              </DashboardCard>

              <BasicInput
                labelText="Identifiant"
                controlId="id"
                control={control}
                name="id"
                placeholder={localDataOperateur.id.value}
                disabled={localDataOperateur.id.disabled}
              />
              <BasicInput
                labelText="Nom de l'opérateur"
                controlId="nom"
                control={control}
                name="nom"
                placeholder="Entrez le nom de l'utilisateur"
                errorMessage={errors.nom?.message}
                registerFunction={register("nom")}
              />
              <BasicInput
                labelText="Prénom de l'opérateur"
                controlId="prenom"
                control={control}
                name="prenom"
                placeholder="Entrez le prénom de l'opérateur"
                errorMessage={errors.prenom?.message}
                registerFunction={register("prenom")}
              />
              <FetchSelect
                urlOptions={apiUrlOperateurGroupe}
                labelText="Groupe"
                onSelectChange={(selectedValue) =>
                  setValue("groupe", selectedValue)
                }
                placeholder="Selectionnez le type"
                defaultIdValue={localDataOperateur.groupe.id.value}
                control={control}
                name="groupe"
                option={{
                  value: "id",
                  text: (row) => (
                    <>
                      <AlignBox>
                        <OperateurGroupeIcon
                          idOperateurGroupe={row.id}
                          size={2}
                          className="me-3"
                        />
                        {row.libelle}
                      </AlignBox>
                    </>
                  ),
                }}
                setValue={setValue}
                errorMessage={errors.type?.message}
                registerFunction={register("type")}
              />
              <BasicInput
                labelText="Date de création"
                controlId="creation"
                control={control}
                name="creation"
                placeholder={localDataOperateur.creation.value}
                disabled={localDataOperateur.creation.disabled}
              />
            </>
          }
          validationButtonText={"Enregistrer l'opérateur"}
          onExited={() => {
            reset({}, { errors: true });
            setLocalDataOperateur(null);
          }}
        >
          <Form noValidate></Form>
        </DashboardModal>
      )}
    </>
  );
};

export default ModalOperateur;
