import React from "react";
import Barcode from "react-barcode";
import "./CustomCode128.css";

const CustomCode128 = ({ value, className, widthLine, height, ...props }) => {
  return (
    <div className="custom-code128" {...props}>
      <Barcode
        value={value}
        background="transparent"
        displayValue={false}
        height={height}
        width={widthLine}
      ></Barcode>
    </div>
  );
};

export default CustomCode128;
