import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import InputLoginFloatingLabel from "../../../components/inputs/InputLoginFloatingLabel/InputLoginFloatingLabel";

const schema = yup
  .object({
    prenom: yup
      .string()
      .required("Veuillez entrer votre prénom.")
      .matches(
        /^[A-Za-zàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ\s/-]{2,}$/,
        'Le prénom ne peut contenir que des minuscules, majuscules, caractères accentués et tiret "-".'
      ),
    nom: yup
      .string()
      .required("Veuillez entrer votre nom.")
      .matches(
        /^[A-Za-zàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ\s/-]{2,}$/,
        'Le nom ne peut contenir que des minuscules, majuscules, caractères accentués et tiret "-".'
      ),
    entreprise: yup
      .string()
      .required("Veuillez entrer le nom de votre entreprise.")
      .matches(
        /^[A-Za-zàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ\s/-]{2,}$/,
        'Ce champ n\'accepte que les minuscules, majuscules, caractères accentués et tiret "-".'
      ),
    email: yup
      .string()
      .required("Veuillez entrer une adresse e-mail")
      .email("Veuillez entrer une adresse email valide."),
    password: yup
      .string()
      .required("Veuillez saisir un mot de passe")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{}|;:'<>,.?/èéêëàáâäçùúûü])[A-Za-z\d!@#$%^&*()_+\-=\[\]{}|;:'<>,.?/èéêëàáâäçùúûü]{8,}$/,
        "Le mot de passe doit contenir au moins: 8 caractères, 1 minuscule, 1 majuscule et 1 caractère spécial"
      ),
    passwordConfirm: yup
      .string()
      .required("Veuillez confirmer votre mot de passe")
      .oneOf(
        [yup.ref("password"), null],
        "Les mots de passes doivent être identiques."
      ),
  })
  .required();

const Register = () => {
  const [ip, setIP] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    // registerRequest(data.prenom, data.nom, data.entreprise, data.email, data.password, ip)
  };

  const getIP = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
  };

  return (
    <>
      <h2 className="text-perform Montserrat-Bold mx-md-5">
        Créez votre compte
      </h2>
      <p className="Montserrat-Regular h6 d-none d-sm-block pb-lg-3">
        Veuillez remplir les champs ci-dessous.
      </p>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <InputLoginFloatingLabel
          controlId="prenomInput"
          label="Prénom"
          type="text"
          placeholder="Votre prénom"
          autoFocus
          name="prenom"
          errors={errors.prenom}
          registerFunction={register("prenom")}
        />
        <p className="text-danger mt-1 ms-1">{errors.prenom?.message}</p>
        <InputLoginFloatingLabel
          controlId="nomInput"
          label="Nom"
          type="text"
          placeholder="Votre Nom"
          name="nom"
          errors={errors.nom}
          registerFunction={register("nom")}
        />
        <p className="text-danger mt-1 ms-1">{errors.nom?.message}</p>
        <InputLoginFloatingLabel
          controlId="entrepriseInput"
          label="Entreprise"
          type="text"
          placeholder="Votre Entreprise"
          name="entreprise"
          errors={errors.entreprise}
          registerFunction={register("entreprise")}
        />
        <p className="text-danger mt-1 ms-1">{errors.entreprise?.message}</p>
        <InputLoginFloatingLabel
          controlId="emailInput"
          label="Adresse e-mail"
          type="email"
          placeholder="Votre adresse e-mail"
          name="email"
          errors={errors.email}
          registerFunction={register("email")}
        />
        <p className="text-danger mt-1 ms-1">{errors.email?.message}</p>
        <InputLoginFloatingLabel
          controlId="passwordInput"
          label="Mot de passe"
          type="password"
          placeholder=""
          name="password"
          setFocus={setFocus}
          errors={errors.password}
          registerFunction={register("password")}
        />
        <p className="text-danger mt-1 ms-1">{errors.password?.message}</p>
        <InputLoginFloatingLabel
          controlId="passwordConfirmInput"
          label="Confirmation du mot de passe"
          type="password"
          placeholder=""
          name="passwordConfirm"
          setFocus={setFocus}
          errors={errors.passwordConfirm}
          registerFunction={register("passwordConfirm")}
        />
        <p className="text-danger mt-1 ms-1">
          {errors.passwordConfirm?.message}
        </p>

        <Button
          // disabled={registerButton}
          type="submit"
          variant="perform"
          className="mt-md-3 px-4 me-2 Montserrat-Regular text-uppercase"
          size="lg"
        >
          Envoyer
        </Button>
        <Link to="/auth/utilisateur/login">
          <Button
            type="button"
            variant="light"
            className="btn-light mt-3 px-4 Montserrat-Regular text-uppercase"
            size="lg"
          >
            Annuler
          </Button>
        </Link>
      </Form>
    </>
  );
};

export default Register;
