const config = {
  authentificationUrl:
    process.env.REACT_APP_API_BASE_URL || "https://api.proferm.net/auth",
  apiUrl: "https://api.proferm.net/api",
};

// const config = {
//   authentificationUrl:
//     process.env.REACT_APP_API_BASE_URL ||
//     "http://127.0.0.1/api.proferm.net/public/auth",
//   apiUrl: "http://127.0.0.1/api.proferm.net/public/api",
// };

export default config;
