import { AnimatePresence } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import TopNavbar from "./components/TopNavbar/TopNavbar";
import Wrapper from "./components/Wrapper/Wrapper";
import Protected from "./utilitaires/Proctected/Protected";
import UserData from "./utilitaires/UserData/UserData";

export const UserContext = React.createContext();
export const FooterContext = React.createContext();

const App = () => {
  const location = useLocation();
  const [prevPathname, setPrevPathname] = useState(location.pathname);
  const [footerRefs, setFooterRefs] = useState();
  const mainAnimation = useRef(true);
  const userData = UserData();
  const [localUserData, setLocalUserData] = useState(null);

  const handleFooterRefs = (refObject) => {
    setFooterRefs(refObject);
  };

  useEffect(() => {
    setLocalUserData(userData);
  }, [userData]);

  useEffect(() => {
    const prevMainPath = prevPathname.split("/")[1];
    const currentMainPath = location.pathname.split("/")[1];
    const isIndexRedirect = location.state && location.state.isIndexRedirect;

    if (prevMainPath === currentMainPath && !isIndexRedirect) {
      mainAnimation.current = false;
    } else {
      mainAnimation.current = true;
    }
    setPrevPathname(location.pathname);
  }, [location.pathname]);

  return (
    <Protected>
      <UserContext.Provider value={localUserData}>
        <FooterContext.Provider value={footerRefs}>
          <AnimatePresence>
            <TopNavbar key={"topnavbar"} />
            <Wrapper key={"wrapper"}>
              <Outlet />
            </Wrapper>
            <Footer key={"footer"} setRef={handleFooterRefs} />
          </AnimatePresence>
        </FooterContext.Provider>
      </UserContext.Provider>
    </Protected>
  );
};

export default App;
