import React from 'react';

const Approvisionnement = () => {

  return (

    <>
      Ici c'est l'approvisionnement
    </>

  );
};

export default Approvisionnement;