import React from 'react';

const Commandes = () => {

  return (

    <>
      Ici c'est les commandes
    </>

  );
};

export default Commandes;