import React, { useEffect, useRef, useState } from "react";
import "react-vertical-timeline-component/style.min.css";
import OperateurGroupeIcon from "../../../../../components/CustomIcons/OperateurGroupeIcon/OperateurGroupeIcon";
import CustomTimeline from "../../../../../components/CustomTimeline/CustomTimeline";
import config from "../../../../../config/config";
import useFetch from "../../../../../services/useFetch";

const TimelinePoste = ({ idEmplacement }) => {
  const apiUrlEmplacementActivity =
    config.apiUrl + "/mouvements-emplacement/" + idEmplacement;
  const isComponentMounted = useRef(true);
  const [localDataEmplacementActivity, setLocalDataEmplacementActivity] =
    useState(null);

  const {
    data: dataEmplacementActivity,
    triggerFetch: triggerFetchEmplacementActivity,
  } = useFetch(apiUrlEmplacementActivity, isComponentMounted, []);

  useEffect(() => {
    if (idEmplacement) {
      triggerFetchEmplacementActivity(apiUrlEmplacementActivity, {
        method: "GET",
      });
    }
  }, [idEmplacement, apiUrlEmplacementActivity]);

  useEffect(() => {
    setLocalDataEmplacementActivity(dataEmplacementActivity);

    return () => {
      setLocalDataEmplacementActivity(null);
    };
  }, [dataEmplacementActivity]);

  return (
    localDataEmplacementActivity && (
      <CustomTimeline layout="1-column-left">
        {localDataEmplacementActivity.map((event) => {
          return (
            <CustomTimeline.Element
              key={event.id}
              titleClassName="text-textural"
              contentArrowStyle={{
                borderRight: "none",
              }}
              date={<>Le {event.creation}</>}
              iconStyle={{
                background: "#00727d",
                color: "#fff",
                width: "20px",
                height: "20px",
                marginLeft: "10px",
                marginTop: "30px",
              }}
              iconTitle={
                <OperateurGroupeIcon
                  idOperateurGroupe={event.operateur.groupe.id}
                  size={3.5}
                  className="me-2"
                />
              }
              title={event.operateur.nom + " " + event.operateur.prenom}
              subTitle={event.operateur.groupe.libelle}
            >
              N° de commande : {parseInt(event.product.codeBarre.cmd)}.
              {event.product.codeBarre.extension} <br />
              Position : {parseInt(event.product.codeBarre.position)} <br />
              Repère quantité :{" "}
              {parseInt(event.product.codeBarre.repere_quantite)}
            </CustomTimeline.Element>
          );
        })}
      </CustomTimeline>
    )
  );
};

export default TimelinePoste;
