import React from "react";
import {
  FaBox,
  FaCartShopping,
  FaCrown,
  FaIndustry,
  FaScrewdriverWrench,
  FaTruck,
  FaUser,
} from "react-icons/fa6";

const LinkIcon = ({ iconName, iconSize }) => {
  switch (iconName) {
    case "FaCartShopping":
      return <FaCartShopping size={iconSize} />;
    case "FaBox":
      return <FaBox size={iconSize} />;
    case "FaIndustry":
      return <FaIndustry size={iconSize} />;
    case "FaScrewdriverWrench":
      return <FaScrewdriverWrench size={iconSize} />;
    case "FaTruck":
      return <FaTruck size={iconSize} />;
    case "FaUser":
      return <FaUser size={iconSize} />;
    case "FaCrown":
      return <FaCrown size={iconSize} />;
    default:
      return <FaCartShopping size={iconSize} />;
  }
};

export default LinkIcon;
