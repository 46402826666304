import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useRef, useState } from "react";
import { Form, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import AlignBox from "../../../../components/AlignBox/AlignBox";
import DashboardCard from "../../../../components/CustomCard/DashboardCard/DashboardCard";
import PfIconPolygon from "../../../../components/CustomIcons/PfIconPolygon/PfIconPolygon";
import StockageTypeIcon from "../../../../components/CustomIcons/StockageTypeIcon/StockageTypeIcon";
import DashboardModal from "../../../../components/CustomModal/DashboardModal/DashboardModal";
import QRCodePDF from "../../../../components/PDF/QRCodePDF/QRCodePDF";
import QRCodeLogo from "../../../../components/QRCodeLogo/QRCodeLogo";
import BasicInput from "../../../../components/inputs/BasicInput/BasicInput";
import FetchSelect from "../../../../components/inputs/FetchSelect/FetchSelect";
import config from "../../../../config/config";
import useFetch from "../../../../services/useFetch";
import TimelineStockage from "./TimelineStockage/TimelineStockage";

const schema = yup
  .object({
    nom: yup.string().required("Nom du stockage obligatoire."),

    type: yup.number().required("Type obligatoire").integer(),
  })
  .required();

const ModalStockage = ({ show, handleClose, idStockage }) => {
  const [idEmplacement, setIdEmplacement] = useState(null);
  const apiUrlStockage = config.apiUrl + "/stockage-form/" + idStockage;
  const apiUrlStockageType = config.apiUrl + "/stockage-types/";
  const isComponentMounted = useRef(true);
  const [localDataStockage, setLocalDataStockage] = useState(null);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    loading: loadingStockage,
    data: dataStockage,
    triggerFetch: triggerFetchStockage,
  } = useFetch(apiUrlStockage, isComponentMounted, []);

  const menu = [
    {
      text: "Activité",
      component: (
        <TimelineStockage idEmplacement={idEmplacement}></TimelineStockage>
      ),
    },
  ];

  useEffect(() => {
    if (show) {
      triggerFetchStockage(apiUrlStockage, {
        method: "GET",
      });
    }
  }, [show]);

  useEffect(() => {
    if (dataStockage) {
      setLocalDataStockage(dataStockage);
      setIdEmplacement(dataStockage.emplacement.id.value);
      setValue("nom", dataStockage.nom.value);
      setValue("type", dataStockage.type.id.value);
    }
  }, [dataStockage]);

  const onSuccess = () => {
    handleClose();
  };

  return (
    <>
      {localDataStockage && (
        <DashboardModal
          dataMenu={menu}
          fullscreen={true}
          show={show}
          handleClose={handleClose}
          iconTitle={
            <StockageTypeIcon
              reverse
              size={1.7}
              idStockageType={localDataStockage.type.id.value}
            />
          }
          title={localDataStockage.nom.value}
          subTitle={localDataStockage.type.libelle.value}
          details={
            <>
              <DashboardCard>
                <Stack direction="horizontal" gap={3}>
                  <div>
                    <DashboardCard theme="light" className="d-inline-block">
                      <QRCodeLogo
                        size={100}
                        viewBox={`0 0 100 100`}
                        value={localDataStockage.qrcode}
                      >
                        <PfIconPolygon size={50}></PfIconPolygon>
                      </QRCodeLogo>
                    </DashboardCard>
                  </div>
                  <div className="w-50">
                    <div className="Montserrat-SemiBold h5 mb-0">
                      Identification
                    </div>
                    <div className="Montserrat-Regular h6 text-uppercase">
                      QR Code
                    </div>
                    <div className="w-100">{localDataStockage.qrcode}</div>
                  </div>
                </Stack>
                <Stack direction="horizontal" gap={3}>
                  <div className="ms-auto">
                    <QRCodePDF
                      qrCodeData={localDataStockage.qrcode}
                      title={localDataStockage.nom.value}
                      text="Imprimer"
                      variant="textural"
                      buttonSize="sm"
                    ></QRCodePDF>
                  </div>
                </Stack>
              </DashboardCard>
              <BasicInput
                labelText="Identifiant"
                controlId="id"
                control={control}
                name="id"
                placeholder={localDataStockage.id.value}
                disabled={!localDataStockage.id.field}
              />
              <BasicInput
                labelText="Nom du stockage"
                controlId="nom"
                control={control}
                name="nom"
                placeholder="Entrez le nom de l'utilisateur"
                errorMessage={errors.nom?.message}
                registerFunction={register("nom")}
              />
              <FetchSelect
                urlOptions={apiUrlStockageType}
                labelText="Type de stockage"
                onSelectChange={(selectedValue) =>
                  setValue("type", selectedValue)
                }
                placeholder="Selectionnez le type"
                defaultIdValue={localDataStockage.type.id.value}
                control={control}
                name="type"
                option={{
                  value: "id",
                  text: (row) => (
                    <>
                      <AlignBox>
                        <StockageTypeIcon
                          idStockageType={row.id}
                          size={2}
                          className="me-3"
                        />
                        {row.libelle}
                      </AlignBox>
                    </>
                  ),
                }}
                setValue={setValue}
                errorMessage={errors.type?.message}
                registerFunction={register("type")}
              />
              <BasicInput
                labelText="Date de création"
                controlId="creation"
                control={control}
                name="creation"
                placeholder={localDataStockage.creation.value}
                disabled={!localDataStockage.creation.field}
              />
            </>
          }
          validationButtonText={"Enregistrer le poste"}
          onExited={() => {
            reset({}, { errors: true });
            setLocalDataStockage(null);
          }}
        >
          <Form noValidate></Form>
        </DashboardModal>
      )}
    </>
  );
};

export default ModalStockage;
