import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Protected = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkUserToken = () => {
      const userToken = localStorage.getItem("token");
      if (!userToken || userToken === "undefined") {
        setIsLoggedIn(false);
        navigate("/auth");
      } else setIsLoggedIn(true);
    };

    checkUserToken();
  }, [navigate]);

  return <>{isLoggedIn ? props.children : null}</>;
};

export default Protected;
