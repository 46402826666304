import React from "react";
import Badge from "react-bootstrap/Badge";
import './statutBadge.css';
import {FaRegCalendarPlus, FaTruck, FaHardHat, FaSave} from "react-icons/fa";
import {FaPersonCircleCheck, FaCheck } from "react-icons/fa6";
import {BiDotsHorizontalRounded} from "react-icons/bi";
import {CgSandClock} from "react-icons/cg";
import {GoAlertFill} from "react-icons/go";


function statutBadge (props) {

    const { statut, type, iconSize } = props;

    const getBadgeContent = () => {
        switch(statut){
            case 'Livraison programmée':
                return (
                    <Badge bg="success" className="w-100" text="white"><FaTruck className="icon" size={iconSize}/> <span className="d-none d-lg-inline-block">{props.statut}</span></Badge>
                );
            case 'Intervention programmée':
                return (
                    <Badge bg="success" className="w-100" text="white"><FaHardHat className="icon" size={iconSize}/> <span className="d-none d-lg-inline-block">{props.statut}</span></Badge>
                );
            case 'Enregistrée':
                return (
                    <Badge bg="primary" className="w-100" text="white"><FaSave className="icon" size={iconSize}/> <span className="d-none d-lg-inline-block">{props.statut}</span></Badge>
                );
            case 'Cloturée':
                return (
                    <Badge bg="light-secondary" className="w-100" text="secondary"><FaCheck className="icon" size={iconSize}/> <span className="d-none d-lg-inline-block">{props.statut}</span></Badge>
                );
            case 'Intervention non soldée':
                return (
                    <Badge bg="light-purple" className="w-100" text="white"><BiDotsHorizontalRounded className="icon" size={iconSize}/> <span className="d-none d-lg-inline-block">{props.statut}</span></Badge>
                );
            case 'Demande d\'informations':
                return (
                    <Badge bg="danger" className="w-100" text="white"><GoAlertFill className="icon" size={iconSize}/> <span className="d-none d-lg-inline-block">{props.statut}</span></Badge>
                );
            case 'Attente de traitement':
                return (
                    <Badge bg="warning" className="w-100" text="white"><CgSandClock className="icon" size={iconSize}/> <span className="d-none d-lg-inline-block">{props.statut}</span></Badge>
                );
            case 'Prise en charge':
                return (
                    <Badge bg="primary" className="w-100" text="white"><FaPersonCircleCheck className="icon" size={iconSize}/> <span className="d-none d-lg-inline-block">{props.statut}</span></Badge>
                );
            case 'Intervention à programmer':
                return (
                    <Badge bg="love" className="w-100" text="white"><FaRegCalendarPlus className="icon" size={iconSize}/> <span className="d-none d-lg-inline-block">{props.statut}</span></Badge>
                );
            default:
                return (
                    <>{props.statut}</>
                );
        }
    }

    const getOutput = () => {
        switch(type){
            case 'badge':
                return getBadgeContent();
            case 'bg' :
                return 'text-' + getBadgeContent().props.text + ' bg-' + getBadgeContent().props.bg;
            case 'icon' :
                return getBadgeContent().props.children[0];
            default :
                return getBadgeContent();
        }
        
    }

    return getOutput();
    
}

export default statutBadge;