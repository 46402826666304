import { yupResolver } from "@hookform/resolvers/yup";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import BasicInput from "../../../../components/inputs/BasicInput/BasicInput";
import config from "../../../../config/config";
import useFetch from "../../../../services/useFetch";
import ScanProduct from "../../scanComponents/ScanProduct";
import ScanResponsable from "../../scanComponents/ScanResponsable";
import ScanEmplacement from "../../scanComponents/scanEmplacement";

const schema = yup
  .object({
    // code_barre: yup.string()
    // .matches(/^[0-9]{8}[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}[1234]{1}[0-9A-Z]{3}$/, 'Le code-barre ne correspond pas aux critères requis')
    // .length(20, 'Le code-barre doit contenir exactement 20 caractères')
    // .required('Le code-barre est requis'),
  })
  .required();

const AtelierPostesPrecedents = ({ handleError, handleSuccess }) => {
  const apiUrlScanAtelier = config.apiUrl + "/scan-atelier/";
  const apiUrlProductRebut = config.apiUrl + "/product-back-force/";
  const inputRef = useRef(null);
  const [scannedOperateur, setScannedOperateur] = useState(null);
  const [scannedEmplacement, setScannedEmplacement] = useState(null);
  const [scannedProduct, setScannedProduct] = useState(null);

  const {
    register,
    formState: { isValid },
    handleSubmit,
    setFocus,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { data, triggerFetch: triggerFetchScanAtelier } = useFetch(
    apiUrlScanAtelier,
    "GET",
    []
  );

  const { triggerFetch: triggerFetchProductRebut } = useFetch(
    apiUrlProductRebut,
    "POST",
    []
  );

  const handleKeyDown = (event) => {
    if (event && event.key === "Enter") {
      event.preventDefault();
      handleSubmit(handleFormSubmit)();
    }
  };

  const handleFormSubmit = (data) => {
    if (isValid) {
      triggerFetchScanAtelier(
        apiUrlScanAtelier + data.code,
        {
          method: "GET",
        },
        false,
        handleError
      );
      inputRef.current.value = "";
    }
  };

  useEffect(() => {
    switch (data?.type) {
      case "operateur":
        setScannedOperateur(data);
        break;
      case "poste":
        setScannedEmplacement(data);
        break;
      case "stockage":
        setScannedEmplacement(data);
        break;
      case "cadre":
        setScannedProduct(data);
        break;
      default:
        break;
    }
  }, [data]);

  useEffect(() => {
    if (scannedEmplacement && scannedProduct && scannedOperateur) {
      const data = {
        operateur: scannedOperateur.code,
        cadre: scannedProduct.code,
        emplacement: scannedEmplacement.code,
      };

      triggerFetchProductRebut(
        apiUrlProductRebut,
        {
          method: "POST",
          body: JSON.stringify(data),
        },
        handleSuccess,
        handleError
      );
    }
  }, [scannedEmplacement, scannedProduct, scannedOperateur]);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        // Clic à l'extérieur du champ, rétablir le focus
        inputRef.current.focus();
      }
    };

    // Ajouter un écouteur d'événements pour gérer les clics à l'extérieur
    document.addEventListener("click", handleClickOutside);

    return () => {
      // Nettoyer l'écouteur d'événements lors du démontage du composant
      document.removeEventListener("click", handleClickOutside);
    };
  }, [inputRef]);

  return (
    <>
      <Form noValidate>
        <BasicInput
          inputRef={inputRef}
          labelText="code"
          controlId="code"
          control={control}
          name="code"
          setFocus={setFocus}
          placeholder="code"
          registerFunction={register}
          onKeyDown={handleKeyDown}
          hidden
        />
      </Form>
      <Row className="h-100">
        <motion.div
          key={1}
          className="col-4 h-100"
          initial={{ x: 200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0 }}
        >
          <ScanResponsable
            operateur={scannedOperateur}
            className="bg-aluminium text-black scan-border-warning"
          />
        </motion.div>
        <motion.div
          key={2}
          className="col-4 h-100"
          initial={{ x: 200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.1 }}
        >
          <ScanEmplacement
            emplacement={scannedEmplacement}
            className="bg-aluminium text-black scan-border-warning"
          />
        </motion.div>
        <motion.div
          key={3}
          className="col-4 h-100"
          initial={{ x: 200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <ScanProduct
            product={scannedProduct}
            className="bg-aluminium text-black scan-border-warning"
            message="attente scan cadre à renvoyer aux postes précédents..."
          />
        </motion.div>
      </Row>
    </>
  );
};

export default AtelierPostesPrecedents;
