import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import NavLinkItem from "../NavLinkItem/NavLinkItem";
import "./ToolNavBar.css";

const ToolNavBar = ({ config }) => {
  return (
    <Navbar expand="sm" className="tool-navbar" data-bs-theme="light">
      <Nav className="me-auto m-0 ms-2">
        {config.map((link, linkIndex) => {
          return (
            <NavLinkItem
              key={linkIndex}
              icon={link.icon}
              text={link.text}
              onClick={link.onClick}
            />
          );
        })}
      </Nav>
    </Navbar>
  );
};

export default ToolNavBar;
