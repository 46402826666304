import React from "react";
import "./OperateurGroupeIcon.css";

const OperateurGroupeIcon = ({
  className,
  size,
  idOperateurGroupe,
  reverse,
  fillOpacity,
}) => {
  return (
    <svg
      className={`operateur-groupe-icon ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      width={`${size}em`}
      height={`${size}em`}
      style={reverse && { transform: "scaleX(-1)" }}
      viewBox="0 0 214.8006 262.32756"
    >
      <ellipse
        className={"operateur-groupe-icon fill-50 stroke-6 " + fillOpacity}
        cx="108.91353"
        cy="27.455643"
        rx="34.137375"
        ry="38.058834"
        transform="matrix(0.95480261,0.29724062,-0.32049019,0.94725184,0,0)"
      />
      <path
        className={"operateur-groupe-icon fill-50 stroke-6 " + fillOpacity}
        d="M 167.28433,146.74168 97.346787,179.3541 27.409244,211.96651 c -1e-6,-34.62057 -0.351381,-74.91311 69.937543,-103.82265 57.508013,-23.652788 69.937543,3.97726 69.937543,38.59782 z"
      />
      {(() => {
        switch (idOperateurGroupe) {
          case "1":
            return (
              <>
                <path
                  className={
                    "operateur-groupe-icon fill-50 stroke-6 " + fillOpacity
                  }
                  d="m 173.90785,219.87259 -29.35437,-9.95436 -24.8416,18.53816 0.39615,-30.99373 -25.307306,-17.89717 29.599206,-9.20082 9.20083,-29.59921 17.89716,25.30731 30.99373,-0.39615 -18.53815,24.8416 z"
                  transform="matrix(0.3114841,-0.6092052,0.6092052,0.3114841,5.7674367,240.03158)"
                />
              </>
            );
          case "2":
            return (
              <>
                <path
                  className={"operateur-groupe-icon stroke-6 " + fillOpacity}
                  d="m 117.86236,214.88477 c 24.34567,-37.84081 67.70517,-37.68466 89.0703,0 -17.9251,36.45045 -67.3294,35.97233 -89.0703,0 z"
                />
                <ellipse
                  className={
                    "operateur-groupe-icon fill-50 stroke-6 " + fillOpacity
                  }
                  id="path1390"
                  cx="162.83266"
                  cy="214.72391"
                  rx="17.980764"
                  ry="26.101717"
                />
              </>
            );
          default:
            return <></>;
        }
      })()}
    </svg>
  );
};

export default OperateurGroupeIcon;
