import React from "react";
import {AnimatePresence, motion} from "framer-motion";
import './Loader.css';
import { useLocation } from "react-router-dom";

function Loader (props) {

    const location = useLocation();

    return (
        <AnimatePresence mode="wait">
            <motion.div
                key={Math.random()}
                className="my-5 mx-auto text-center"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{ ease: "easeInOut", duration: 0.3}}
            >
                <motion.svg
                    width="30"
                    height="40"
                    viewBox="0 0 600 700"
                    initial={{ opacity: 1, rotateY: 0, fill: '#000000' }}
                >
                    <motion.polygon
                        points="0,0 600,100 600,600 0,700"
                        animate={{
                            rotateY:[0,180,360,180, 0, 180, 0],
                            fill:['#000000', '#dacc2e','#52b876', '#00727d', '#859da9', '#000000']
                        }}
                        transition={{
                            duration: 8,
                            repeat: Infinity,
                            repeatDelay: 0,
                        }}
                    />
                </motion.svg>
                <motion.div 
                    className="mt-2"
                    animate={{
                        opacity: [0.5,1,0.5]
                    }}
                    transition={{
                            duration: 1.6,
                            repeat: Infinity,
                            repeatDelay: 0,
                    }}
                >
                    {props.children}
                </motion.div>
            </motion.div>
        </AnimatePresence>
    );
}

export default Loader;