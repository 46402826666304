import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { Col } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import PosteIcon from "../../../components/CustomIcons/PosteIcon/PosteIcon";
import PosteTypeIcon from "../../../components/CustomIcons/PosteTypeIcon/PosteTypeIcon";
import StockageTypeIcon from "../../../components/CustomIcons/StockageTypeIcon/StockageTypeIcon";
import "./ScanComponents.css";

const ScanEmplacement = ({
  emplacement,
  className,
  message = "attente scan \nemplacement...",
  icon = <PosteIcon tache="scan_emplacement" size={70 + "%"} />,
}) => {
  const emplacementType = emplacement && emplacement.type;
  const dataEmplacement = emplacement && emplacement.data;

  return (
    <AnimatePresence mode="wait">
      {!dataEmplacement ? (
        <motion.div
          className="h-100"
          key="default"
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ y: -50, opacity: 0 }}
          transition={{ duration: 0.25, ease: [0.17, 0.67, 0.83, 0.67] }}
        >
          <Col
            xs={12}
            className={
              className +
              " bg-perform text-black text-uppercase h2 fw-bold rounded justify-content-center align-items-center text-center h-100"
            }
          >
            {icon}
            <div>{message}</div>
          </Col>
        </motion.div>
      ) : emplacementType === "poste" ? (
        <motion.div
          className="h-100"
          key="poste"
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ y: -50, opacity: 0 }}
          transition={{ duration: 0.25, ease: [0.17, 0.67, 0.83, 0.67] }}
        >
          <Col
            xs={12}
            className={
              className + " bg-hybride rounded h-100 position-relative"
            }
          >
            <div className="text-uppercase w-100 h3 pt-4 text-center">
              <PosteTypeIcon idPosteType={dataEmplacement.type.id} size={4.7} />
              <br />
              <br />
              {emplacementType} scanné
            </div>
            <div className="card h4 m-4 p-3">
              <p>{dataEmplacement.nom}</p>
              <p>type: {dataEmplacement.type.libelle}</p>
            </div>
            <div className="text-center position-absolute bottom-0 w-100 mb-4">
              <FaCheckCircle size={20 + "%"} />
            </div>
          </Col>
        </motion.div>
      ) : (
        <motion.div
          className="h-100"
          key="poste"
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ y: -50, opacity: 0 }}
          transition={{ duration: 0.25, ease: [0.17, 0.67, 0.83, 0.67] }}
        >
          <Col
            xs={12}
            className={
              className + " bg-hybride rounded h-100 position-relative "
            }
          >
            <div className="text-uppercase w-100 h3 pt-4 text-center">
              <StockageTypeIcon
                idStockageType={dataEmplacement.type.id}
                size={4.7}
              />
              <br />
              <br />
              {emplacementType} scanné
            </div>
            <div className="card h4 m-4 p-3">
              <p>{dataEmplacement.nom}</p>
              <p>Type: {dataEmplacement.type.libelle}</p>
            </div>
            <div className="text-center position-absolute bottom-0 w-100 mb-4">
              <FaCheckCircle size={20 + "%"} />
            </div>
          </Col>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ScanEmplacement;
