import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { PiPlusCircleThin } from "react-icons/pi";
import { FooterContext, UserContext } from "../../../App";
import UserProfermIcon from "../../../components/CustomIcons/UserProfermIcon/UserProfermIcon";
import ResizableTable from "../../../components/ResizableTable/ResizableTable";
import ToolNavBar from "../../../components/ToolNavBar/ToolNavBar";
import config from "../../../config/config";
import FormatDateDDMMYYYY from "../../../utilitaires/FormatDate/FormatDateDDMMYYYY";
import FormatDateDDMMYYYYHHMM from "../../../utilitaires/FormatDate/FormatDateDDMMYYYYHHMM";
import { AdministrationFreeRefContext } from "../Administration";
import ModalNouvelUtilisateurProferm from "./ModalNouvelUtilisateurProferm/ModelNouvelUtilisateurProferm";
import ModalUtilisateurProferm from "./ModalUtilisateurProferm/ModalUtilisateurProferm";

function UtilisateurProferm(props) {
  const [showNouvelUtilisateurProferm, setShowNouvelUtilisateurProferm] =
    useState(false);
  const searchBoxRefContext = useContext(AdministrationFreeRefContext);
  const [showUtilisateurProferm, setShowUtilisateurProferm] = useState(false);
  const [idUtilisateurProferm, setIdUtilisateurProferm] = useState(null);
  const footerRefs = useContext(FooterContext);
  const userData = useContext(UserContext);
  const userId = userData.id;
  const apiUrl = config.apiUrl;
  const resizableTableRef = useRef();

  const handleShowNouvelUtilisateurProfermModal = () =>
    setShowNouvelUtilisateurProferm(true);
  const handleHideNouvelUtilisateurProfermModal = () => {
    resizableTableRef.current.refreshTable();
    setShowNouvelUtilisateurProferm(false);
  };

  const handleShowUtilisateurProfermModal = (idUtilisateurProferm) => {
    setIdUtilisateurProferm(idUtilisateurProferm);
    setShowUtilisateurProferm(true);
  };

  const handleHideUtilisateurProfermModal = () => {
    resizableTableRef.current.refreshTable();
    setShowUtilisateurProferm(false);
  };

  const toolBarConfig = [
    {
      text: "Nouvel Utilisateur",
      icon: <PiPlusCircleThin size={20} />,
      onClick: handleShowNouvelUtilisateurProfermModal,
    },
  ];

  const tableConfig = [
    {
      nom: "",
      key: "groupe.id",
      render: (row) => <UserProfermIcon size={2.5} idGroupe={row.groupe.id} />,
      width: 20,
      center: true,
    },
    {
      nom: "Identifiant",
      key: "id",
      render: (row) => row.id,
      width: 90,
      center: true,
    },
    {
      nom: "Login",
      key: "login",
      render: (row) => row.login,
    },
    {
      nom: "",
      key: "groupe.libelle",
      render: (row) => row.groupe.libelle,
      width: 180,
      center: true,
    },
    {
      nom: "Prénom Nom",
      key: "prenom",
      render: (row) => (
        <>
          {row.prenom} {row.nom}
        </>
      ),
      width: 180,
      center: true,
    },
    {
      nom: "Création",
      key: "creation.date",
      render: (row) => (
        <FormatDateDDMMYYYY>{row.creation.date}</FormatDateDDMMYYYY>
      ),
      width: 120,
      center: true,
    },
    {
      nom: "IP création",
      key: "creation.ip",
      render: (row) => row.creation.ip,
      width: 80,
    },
    {
      nom: "Dernière connexion",
      key: "last_connexion.date",
      render: (row) => (
        <FormatDateDDMMYYYYHHMM>
          {row.last_connexion.date}
        </FormatDateDDMMYYYYHHMM>
      ),
      width: 180,
      center: true,
    },
    {
      nom: "IP dernière connexion",
      key: "last_connexion.ip",
      render: (row) => row.last_connexion.ip,
      width: 180,
    },
  ];

  const cardConfig = {
    header: (row) => (
      <>
        {row.prenom} {row.nom}
      </>
    ),
    headerClass: () => "bg-aluminium text-white",
    title: (row) => row.groupe.libelle,
    icon: (row) => <UserProfermIcon size={3} idGroupe={row.groupe.id} />,
    text: (row) => (
      <>
        id: {row.id}
        <br />
        login: <strong>{row.login}</strong>
        <br />
        création:{" "}
        <FormatDateDDMMYYYYHHMM>{row.creation.date}</FormatDateDDMMYYYYHHMM>
        <br />
        {">"} IP: {row.creation.ip}
        <br />
        dernière connexion:{" "}
        <FormatDateDDMMYYYYHHMM>
          {row.last_connexion.date}
        </FormatDateDDMMYYYYHHMM>
        <br />
        {">"} IP: {row.last_connexion.ip}
      </>
    ),
  };

  return (
    <>
      <ModalNouvelUtilisateurProferm
        show={showNouvelUtilisateurProferm}
        handleClose={handleHideNouvelUtilisateurProfermModal}
      />
      <ModalUtilisateurProferm
        show={showUtilisateurProferm}
        handleClose={handleHideUtilisateurProfermModal}
        idUtilisateurProferm={idUtilisateurProferm}
      ></ModalUtilisateurProferm>
      <ToolNavBar config={toolBarConfig} />
      <Container fluid className="ps-0">
        <Row>
          <Col>
            <Card className="border-0">
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0, y: 25 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <ResizableTable
                    ref={resizableTableRef}
                    urlFetch={apiUrl + "/user-proferm-table/" + userId}
                    limit={50}
                    heightAdjust={82}
                    indexOrder="user_proferm_id"
                    viewModeInit="table"
                    indexSort={1}
                    sortInit="DESC"
                    searchInputRef={searchBoxRefContext}
                    switchViewRef={searchBoxRefContext}
                    paginationRef={footerRefs && footerRefs.middle}
                    filterResultRef={footerRefs && footerRefs.start}
                    totalRef={footerRefs && footerRefs.end}
                    tableConfig={tableConfig}
                    cardConfig={cardConfig}
                    rowClick={handleShowUtilisateurProfermModal}
                  />
                </motion.div>
              </AnimatePresence>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UtilisateurProferm;
