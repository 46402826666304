import React from "react";
import Moment from "react-moment";

export function FormatDateStringDDMMYYYYHHMM(props) {
  const formattedDate = Moment(props.children).format("DD/MM/YYYY HH:mm");

  return <span>{formattedDate}</span>;
}

function FormatDateDDMMYYYYHHMM(props) {
  return <Moment format="DD/MM/YYYY HH:MM">{props.children}</Moment>;
}

export default FormatDateDDMMYYYYHHMM;
