import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import "./AtelierSuccess.css";

const AtelierSuccess = ({ successIcon, successMessage, clearSuccess }) => {
  return (
    <Row className="w-100 h-100" onClick={() => clearSuccess()}>
      <Col
        xs={12}
        className="text-success d-flex justify-content-center align-items-center"
      >
        <div className="text-center">
          <p className="atelier-success">{successIcon}</p>
          <p className="atelier-success text-uppercase fw-bold">
            {successMessage}
          </p>
          <p>
            <Spinner animation="border" />
          </p>
        </div>
      </Col>
    </Row>
  );
};

export default AtelierSuccess;
