import { AnimatePresence, motion } from "framer-motion";
import React, { useContext } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FooterContext, UserContext } from "../../../App";
import ResizableTable from "../../../components/ResizableTable/ResizableTable";
import ClassificationBadge from "../../../components/badgeComponents/ClassificationBadge/ClassificationBadge";
import config from "../../../config/config";
import FormatDateDDMMYYYY from "../../../utilitaires/FormatDate/FormatDateDDMMYYYY";
import FormatDateDDMMYYYYHHMM from "../../../utilitaires/FormatDate/FormatDateDDMMYYYYHHMM";
import { AdministrationFreeRefContext } from "../Administration";

function UtilisateurClient(props) {
  const searchBoxRefContext = useContext(AdministrationFreeRefContext);
  const footerRefs = useContext(FooterContext);
  const userData = useContext(UserContext);
  const userId = userData.id;
  const apiUrl = config.apiUrl;

  const tableConfig = [
    {
      nom: "Identifiant",
      key: "id",
      render: (row) => row.id,
      width: 100,
      center: true,
    },
    {
      nom: "Login",
      key: "login",
      render: (row) => row.login,
      width: 60,
      center: true,
    },
    {
      nom: "Prénom Nom",
      key: "prenom",
      render: (row) => (
        <>
          {row.prenom} {row.nom}
        </>
      ),
      width: 240,
      center: true,
    },
    {
      nom: "client",
      key: "client.nom",
      render: (row) => (
        <>
          {row.client.nom}
          <ClassificationBadge
            classification={row.client.classification.libelle}
          />
        </>
      ),
      center: true,
    },
    {
      nom: "Création",
      key: "creation.date",
      render: (row) => (
        <FormatDateDDMMYYYY>{row.creation.date}</FormatDateDDMMYYYY>
      ),
      width: 120,
      center: true,
    },
    {
      nom: "IP création",
      key: "creation.ip",
      render: (row) => row.creation.ip,
      width: 80,
    },
    {
      nom: "Dernière connexion",
      key: "last_connexion.date",
      render: (row) => (
        <FormatDateDDMMYYYYHHMM>
          {row.last_connexion.date}
        </FormatDateDDMMYYYYHHMM>
      ),
      width: 180,
      center: true,
    },
    {
      nom: "IP dernière connexion",
      key: "last_connexion.ip",
      render: (row) => row.last_connexion.ip,
      width: 180,
    },
  ];

  const cardConfig = {
    header: (row) => (
      <>
        {row.prenom} {row.nom}
      </>
    ),
    headerClass: (row) => "bg-light-aluminium text-white",
    title: (row) => (
      <>
        {row.client.nom}
        <ClassificationBadge
          className="mt-0"
          classification={row.client.classification.libelle}
        />
      </>
    ),
    text: (row) => (
      <>
        id: {row.id}
        <br />
        id client 3E: {row.client.numero} <br />
        login: <strong>{row.login}</strong>
        <br />
        création:{" "}
        <FormatDateDDMMYYYYHHMM>{row.creation.date}</FormatDateDDMMYYYYHHMM>
        <br />
        {">"} IP: {row.creation.ip}
        <br />
        dernière connexion:{" "}
        <FormatDateDDMMYYYYHHMM>
          {row.last_connexion.date}
        </FormatDateDDMMYYYYHHMM>
        <br />
        {">"} IP: {row.last_connexion.ip}
      </>
    ),
  };

  return (
    <Container fluid className="ps-0">
      <Row>
        <Col>
          <Card className="border-0">
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0, y: 25 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2 }}
              >
                <ResizableTable
                  urlFetch={apiUrl + "/user-table/" + userId}
                  limit={50}
                  indexOrder="user_id"
                  viewModeInit="table"
                  heightAdjust={37}
                  indexSort={0}
                  sortInit="DESC"
                  searchInputRef={searchBoxRefContext}
                  TableConfig={tableConfig}
                  switchViewRef={searchBoxRefContext}
                  paginationRef={footerRefs && footerRefs.middle}
                  filterResultRef={footerRefs && footerRefs.start}
                  totalRef={footerRefs && footerRefs.end}
                  tableConfig={tableConfig}
                  cardConfig={cardConfig}
                />
              </motion.div>
            </AnimatePresence>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default UtilisateurClient;
