import React from "react";
import {PiCircuitryFill} from "react-icons/pi"

function IconSecteur (props) {
    return(
        props.children === 'aucun' ? 
            <PiCircuitryFill className="text-secondary" size={'2em'}/> 
            : 
            <PiCircuitryFill className="text-light-secondary" size={'2em'}/>
    );
}

export default IconSecteur;