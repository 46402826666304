import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { PiPlusCircleThin } from "react-icons/pi";
import { FooterContext } from "../../../App";
import PosteTypeIcon from "../../../components/CustomIcons/PosteTypeIcon/PosteTypeIcon";
import ResizableTable from "../../../components/ResizableTable/ResizableTable";
import ToolNavBar from "../../../components/ToolNavBar/ToolNavBar";
import config from "../../../config/config";
import { FreeSecondNavBarContext } from "../Production";
import ModalNouveauPoste from "./ModalNouveauPoste/ModalNouveauPoste";
import ModalPoste from "./ModalPoste/ModalPoste";

function Postes() {
  const [showNouveauPoste, setShowNouveauPoste] = useState(false);
  const [showPoste, setShowPoste] = useState(false);
  const [idPoste, setIdPoste] = useState(null);
  const searchBoxRefContext = useContext(FreeSecondNavBarContext);
  const footerRefs = useContext(FooterContext);
  const apiUrl = config.apiUrl;
  const resizableTableRef = useRef();

  const handleShowNouveauPosteModal = () => setShowNouveauPoste(true);
  const handleHideNouveauPosteModal = () => {
    resizableTableRef.current.refreshTable();
    setShowNouveauPoste(false);
  };

  const handleShowPosteModal = (idPoste) => {
    setIdPoste(idPoste);
    setShowPoste(true);
  };
  const handleHidePosteModal = () => {
    resizableTableRef.current.refreshTable();
    setShowPoste(false);
  };

  const tableConfig = [
    {
      nom: "Type",
      key: "type.libelle",
      render: (row) => (
        <>
          <PosteTypeIcon idPosteType={row.type.id} size={2} className="me-2" />
          {row.type.libelle}
        </>
      ),
      width: 80,
    },
    {
      nom: "Nom",
      key: "nom",
      render: (row) => row.nom,
      width: 240,
    },
    {
      nom: "ID",
      key: "id",
      render: (row) => row.id,
      width: 120,
      center: true,
    },
    {
      nom: "Création",
      key: "creation",
      render: (row) => row.creation,
      width: 120,
      center: true,
    },
  ];

  const toolBarConfig = [
    {
      text: "Nouveau poste",
      icon: <PiPlusCircleThin size={20} />,
      onClick: handleShowNouveauPosteModal,
    },
  ];

  const cardConfig = {
    header: (row) => row.nom,
    headerClass: () => "bg-hybride",
    icon: (row) => (
      <PosteTypeIcon idPosteType={row.type.id} size={4} className="pt-2" />
    ),
    title: (row) => <>{row.type.libelle}</>,
    text: (row) => (
      <>
        id: {row.id} <br />
        creation: {row.creation}
      </>
    ),
  };

  return (
    <>
      <ModalNouveauPoste
        show={showNouveauPoste}
        handleClose={handleHideNouveauPosteModal}
      ></ModalNouveauPoste>
      <ModalPoste
        show={showPoste}
        handleClose={handleHidePosteModal}
        idPoste={idPoste}
      ></ModalPoste>
      <ToolNavBar config={toolBarConfig} />
      <Container fluid className="ps-0">
        <Row>
          <Col>
            <Card className="border-0">
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0, y: 25 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <ResizableTable
                    ref={resizableTableRef}
                    urlFetch={apiUrl + "/poste-datatable"}
                    limit={50}
                    heightAdjust={82}
                    indexOrder="poste_id"
                    viewModeInit="card"
                    indexSort={2}
                    sortInit="DESC"
                    searchInputRef={searchBoxRefContext}
                    paginationRef={footerRefs && footerRefs.middle}
                    filterResultRef={footerRefs && footerRefs.start}
                    totalRef={footerRefs && footerRefs.end}
                    TableConfig={tableConfig}
                    switchViewRef={searchBoxRefContext}
                    tableConfig={tableConfig}
                    cardConfig={cardConfig}
                    rowClick={handleShowPosteModal}
                  />
                </motion.div>
              </AnimatePresence>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Postes;
