import React, { useState } from 'react';
import {PiHouse, PiUserCircle, PiUserCircleGear} from 'react-icons/pi'
import SecondNavBar from '../../components/SecondNavBar/SecondNavBar';
import { Outlet, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

export const AdministrationFreeRefContext = React.createContext();

const Administration = () => {

    const location = useLocation();
    const [freeRef, setFreeRef] = useState(null);

    const handleAdministrationFreeRef = (freeSecondNavBarRef) => {
        setFreeRef(freeSecondNavBarRef.current);
    }

    const links = [
      {
        to: 'tableau_de_bord',
        icon: <PiHouse size={'1.1em'}/>,
        text: 'Tableau de bord'
      },
      {
        to: 'utilisateur_proferm',
        icon: <PiUserCircleGear size={'1.3em'}/>,
        text: 'Utilisateurs Proferm'
      },
      {
        to: 'utilisateur_client',
        icon: <PiUserCircle size={'1.3em'}/>,
        text: 'Utilisateurs client'
      }
    ]
    

    return (
      
      <AdministrationFreeRefContext.Provider value={freeRef}>
        <SecondNavBar links={links} setRef={handleAdministrationFreeRef}/>
        <AnimatePresence mode='wait'>
          <motion.div
            key={location.pathname}
            initial={{y: 25, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            transition={{duration: 0.2}}
          >
            <Outlet/>
          </motion.div>
        </AnimatePresence>
        
      </AdministrationFreeRefContext.Provider>

    );
};

export default Administration;