import React from 'react';

const Logistique = () => {

  return (

    <>
      Ici c'est la logistique
    </>

  );
};

export default Logistique;