import { useEffect } from "react";

export function useArrowScroll(ref) {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowUp") {
        ref.current.scrollBy(0, -50);
      } else if (event.key === "ArrowDown") {
        ref.current.scrollBy(0, 50);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [ref]);
}
