import React from 'react';
import { PiHouse, PiPlus } from 'react-icons/pi';
import SecondNavBar from '../../components/SecondNavBar/SecondNavBar';

const Clients = () => {

  const links = [
    {
      to: 'tableau_de_bord',
      icon: <PiHouse size={'1.1em'}/>,
      text: 'Tableau de bord'
    },
    {
      to: 'nouveau_client',
      icon: <PiPlus size={'1.1em'}/>,
      text: 'Nouveau client'
    }
  ]
  

  return (
    
    <>
      <SecondNavBar links={links}/>
      Ici c'est les clients
    </>

  );
};

export default Clients;