import { motion } from "framer-motion";
import React from "react";
import { Row } from "react-bootstrap";
import BoutonAtelierMenu from "./BoutonAtelierMenu/BoutonAtelierMenu";

const AtelierMenu = ({ links }) => {
  return (
    <Row className="w-100">
      {links.map((link, index) => {
        const animationLinks = {
          initial: { opacity: 0, y: 100 },
          animate: { opacity: 1, y: 0, transition: { delay: index * 0.025 } },
        };

        return (
          <motion.div
            key={index}
            className="col-lg-2 col-md-3 mb-4"
            initial="initial"
            animate="animate"
            variants={animationLinks}
          >
            <BoutonAtelierMenu
              key={index}
              icon={link.icon}
              onClick={link.onClick}
            >
              {link.text}
            </BoutonAtelierMenu>
          </motion.div>
        );
      })}
    </Row>
  );
};

export default AtelierMenu;
