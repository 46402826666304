import { motion } from "framer-motion";
import React from "react";
import { Button } from "react-bootstrap";
import { FaArrowRight, FaBars } from "react-icons/fa6";
import "./BigButtonMenu.css";

const BigButtonMenu = ({ onClick, isShowMenu }) => {
  return (
    <motion.div className="position-fixed end-0 bottom-0 mb-5 me-3">
      <Button
        className="button-atelier-menu bg-textural rounded-circle text-white"
        onClick={() => {
          onClick();
        }}
      >
        {isShowMenu ? (
          <FaArrowRight size={30}></FaArrowRight>
        ) : (
          <FaBars size={30}></FaBars>
        )}
      </Button>
    </motion.div>
  );
};

export default BigButtonMenu;
