import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { PiMagnifyingGlass } from "react-icons/pi";
import "./InputSearchBar.css";

const SearchBar = ({ onChange }) => {
  const [isFocused, setFocused] = useState(false);

  return (
    <div className="position-relative">
      <div className={`loupe position-absolute ${isFocused ? "focused" : ""}`}>
        <PiMagnifyingGlass size={15} />
      </div>
      <Form className="form-navbar-secondaire">
        <InputGroup>
          <Form.Control
            type="text"
            placeholder="Rechercher dans la liste"
            className="mr-sm-2"
            onChange={(e) => onChange(e.target.value)}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onKeyDown={(e) => {
              if (e.key === "Enter") e.preventDefault();
            }}
          />
        </InputGroup>
      </Form>
    </div>
  );
};

export default SearchBar;
