import React from "react";
import { LuPackage, LuUserCog2 } from "react-icons/lu";

function IconTypeSav (props) {
    if(props.children === "Livraison"){
        return(
            <LuPackage size={props.size ? props.size+'em' : '1.9em'} className={props.className}/>
        );
    }else{
        return(
            <LuUserCog2 size={props.size ? props.size+'em' : '1.9em'} className={props.className}/>
        );
    }
    
}

export default IconTypeSav;