import React from 'react';
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import { Form, Button } from 'react-bootstrap';
import * as yup from 'yup';
import InputLoginFloatingLabel from '../../../components/inputs/InputLoginFloatingLabel/InputLoginFloatingLabel';

const schema = yup.object({
  email: yup
  .string()
  .required("Veuillez entrer votre adresse e-mail.")
  .email("Veuillez entrer une adresse email valide."),
}).required();

const Forgot = () => {

  const {register, handleSubmit, formState:{errors}} = useForm({
      resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {       
  }

  return (
    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
      <h2 className="text-perform Montserrat-Bold mb-4">Mot de passe oublié ?</h2>
      <p className="Montserrat-Regular h6 pb-3">Veuillez entrer votre adresse e-mail de connexion.</p>
      <InputLoginFloatingLabel
        controlId="loginInput"
        label="Adresse e-mail"
        type="email" 
        placeholder="entreprise@exemple.com" 
        autoFocus
        name="email"
        errors={errors.email}
        registerFunction={register('email')}
      />
      <p className="text-danger mt-1 ms-1">{errors.login?.message}</p>
      <p className="text-danger mt-1 ms-1">{errors.email?.message}</p>
      <Button 
        type="submit" 
        variant="perform" 
        className="mt-3 px-4 me-2 Montserrat-Regular text-uppercase" 
        size="lg"
      >
        Valider
      </Button>
      <Link to="/auth/utilisateur/login">
        <Button 
          type="button" 
          variant="light" 
          className="btn-light mt-3 px-4 Montserrat-Regular text-uppercase" 
          size="lg"
        >
          Annuler
        </Button>
      </Link>
    </Form>  
  );
};

export default Forgot;